/* .tab-bar {
	height: var(--ion-tab-bar-height);
	flex: 0, 0, var(--ion-tab-bar-height);
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	border: 1px solid var(--ion-tab-bar-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.07))));
}

.tab {
	height: 100%;
	flex: 1 1 0%;
	text-align: center;
	color: var(--ion-tab-bar-color, var(--ion-color-step-600));
	font-size: 12px;
	cursor: pointer;
}

.tab:hover, .tab.selected {
	color: var(--ion-color-primary);
}

.tab > div {
	padding: 8px 12px 0;
	vertical-align: middle;
}

.tab .icomoon, .tab ion-icon {
	font-size: 20px;
	line-height: 25px;
}

.toast-wrapper.toast-bottom {
	bottom: calc(10px + var(--ion-tab-bar-height)) !important;
} */