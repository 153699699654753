.header-md::after {
	background-image: none;
}

ion-toolbar.dynamic-header {
	--min-height: var(--ion-toolbar-min-height);
	--ion-toolbar-background: transparent;
	--ion-toolbar-color: #fff;
}

.dynamic-header ion-title {
	padding: 0px;
	text-align: center;
}
ion-toolbar.dynamic-header {
	display: none;
}

ion-toolbar.web-menu.dynamic-header {
	display: flex;
	--background: var(--ion-color-tertiary);
}

ion-list.web-menu-subheader-list {
	background: var(--ion-color-tertiary);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding-top: 0!important;
}

.web-menu-subheader-item {
	--background: var(--ion-color-tertiary);
	cursor: pointer;
	--inner-padding-end: 0;
	--padding-end: 10px;
	--padding-start: 10px;
}

.web-menu-subheader-item > ion-label {
	font-weight: 500;
	font-size: 0.9rem!important;
	text-align: center;
}

.web-menu-subheader-item:hover,
.web-menu-subheader-item.active {
	--background: var(--ion-color-white);
}

.web-menu-subheader-item:hover > ion-label,
.web-menu-subheader-item.active > ion-label {
	color: var(--ion-color-tertiary);
}

@media screen and (min-width: 768px) {
	ion-list.web-menu-subheader-list {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.web-menu-subheader-item {
		margin: 10px 0;
	}
}
/* ion-toolbar.dynamic-header {
	display: initial;
	--ion-toolbar-background: unset;
} */
.footer {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding: 25px;
	background: #222;
	color: var(--ion-color-white);
}

.footer div {
	display: flex;
}

.footer div ion-icon {
	font-size: 1.5rem;
	padding: 10px;
	cursor: pointer;
}

.footer p {
	text-transform: uppercase;
	margin: 20px 0 0 0;
	cursor: pointer;
	font-size: 0.8rem;
}
.right-popover {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	position: absolute;
	top: 0;
	right: -1px;
	bottom: 0;
	width: 50vw;
	margin: 0;
	background: var(--ion-color-primary);
	z-index: 100;
	padding: 0;
	transform: translateX(100%);
	opacity: 0;
	transition: .2s ease-out;
}

.right-popover ion-item {
	--background: transparent;
	--color: var(--ion-color-white);
	--inner-padding-end: 0;
	font-size: 1.4rem;
	cursor: pointer;
}

.right-popover ion-item ion-label {
	text-align: center;
	margin: 12px 8px 12px 8px;
	font-family: "Klinic Slab"!important;
}

.right-popover.visible {
	opacity: 1;
	transform: translateX(0);
	padding-top: 50px;
}

.right-popover-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 90;
	transform: translateX(100%);
	opacity: 0;
	transition: .2s ease-out;
	background: rgba(0,0,0,0.2);
}

.right-popover-backdrop.visible{
	opacity: 1;
	transform: translateX(0);
}

@media screen and (min-width: 768px) {
	.right-popover {
		width: 40vw;
	}
} 
@media screen and (min-width: 992px) {
	.right-popover {
		width: 30vw;
	}

	.right-popover ion-item {
		font-size: 1.8rem;
	}
} 


@media screen and (min-width: 1200px) {
	.right-popover ion-item {
		font-size: 2.5rem;
	}
}
#main > .content-wrapper > ion-page, #main > .content-wrapper > div.ion-page {
	z-index: 5;
}

#main > .content-wrapper > ion-page.blank, #main > .content-wrapper > div.ion-page.blank {
	top: 0;
}

.content-wrapper {
	flex: 1 1 auto;
	position: relative;
}

.ion-page {
    min-width: 285px;
}

.no-padding.desktop,
.ion-padding.desktop {
	display: flex;
	flex-flow: column;
	flex: 1 1;
	position: relative;
}

.desktop-content-wrapper {
	display: flex;
	flex-flow: column;
	height: auto;
	min-height: 100%;
}

.curved-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: none;
	opacity: 0;
}

.curved-text.curved {
	opacity: 1;
	transition: opacity 200ms ease-out;
}

.curved-text > div {
	display: inline-block;
	text-transform: uppercase;
}

.animated-gif-01 {
	--background: url(/static/media/video-bg.5553fc4f.gif) transparent no-repeat 50% 100% / 100% auto
}

.welcome {
	position: relative;
	margin-bottom: 30px;
}

/* .welcome div:first-child {
	--background: #fff;
	padding-bottom: 15%;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 90% auto;
	box-shadow: none;
} */

.welcome div:last-child {
	text-transform: uppercase;
	font-family: 'Klinic Slab' !important;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-size: 2em;
	font-weight: bold;
}

.welcome > div:first-child {
	font-size: 1.2em;
    padding-bottom: 15px;
}

/* dash-grid */

/* .dash-grid-padding {
	padding: 25px;
} */

.dash-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-row-gap: 2px;
	grid-column-gap: 2px;
}

.dash-grid > div {
	padding: 8px 20px 30px;
	text-align: center;
	background-color: rgba(114,208,235,0.4);
	color: #fff;
	position: relative;
}

/* .dash-grid > div .dash-grid-bg {
	padding-top: 74%;
	background-position: 50% 50%;
	background-size: auto 80%;
	background-repeat: no-repeat;
} */

/* .dash-grid > div .dash-grid-label {
	position: absolute;
	left: 5px;
	right: 5px;
	bottom: 5px;
	font-family: var(--ion-font-family);
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	font-size: 3vw;
	font-size: calc(50% + 1.5vw);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
} */

.dash-grid > div:first-child {
	grid-column: span 2;
	padding-top: 0;
	padding-bottom: 20px;
}

.dash-grid > div:first-child .dash-grid-bg {
	background-image: url(/static/media/bowl-icon.94074e1a.png);
}

.dash-grid-bg.scan {
	background-image: url(/static/media/qr-scan.60e50931.svg);
}

.dash-grid-bg.rewards {
	background-image: url(/static/media/reward-icon.55a01db2.png);
}

.dash-grid-bg.locations {
	background-image: url(/static/media/icon-location.6f13b0b7.svg);
}

.dash-grid-bg.refer {
	background-image: url(/static/media/referfriend-icon.e08322f1.png);
}

ion-content .ion-padding {
	-webkit-padding-start: 5px;
	        padding-inline-start: 5px;
    -webkit-padding-end: 5px;
            padding-inline-end: 5px;
}

.dash-grid-padding {
	max-width: 400px;
	margin: 0 auto;
}


.welcome div:first-child {
	--background: #fff;
	padding-bottom: 15%;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 100% auto;
	box-shadow: none;
}

.dash-grid > div {
	cursor: pointer;
}

.dash-grid > div .dash-grid-bg {
	padding-top: 44%;
	background-position: 50% 50%;
	background-size: auto 70%;
	background-repeat: no-repeat;
}

.dash-grid > div .dash-grid-label {
	position: absolute;
	left: 5px;
	right: 5px;
	bottom: 5px;
	font-family: var(--ion-font-family);
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	font-size: 3vw;
	font-size: calc(50% + 0.8vw);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.dash-grid > div:first-child .dash-grid-bg {
	padding-top: 30%;
}

.start-order-wrapper {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.start-order {
	/* flex: 1 1 auto; */
	display: flex;
	flex-flow: column;
	/* padding: 2% 2%; */
}

.start-order > .start-order-find,
.start-order > .start-order-pic {
	background: var(--ion-color-white);
	padding: 40px;
}

.start-order > .start-order-find {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	flex: 1 1 auto;
	overflow-y: auto;
}

.start-order-top {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

.start-order-buttons {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
}

.start-order > .start-order-find h2 {
	margin: 0 0 20px 0;
	color: var(--ion-color-black);
	text-transform: uppercase;
	font-family: "Klinic Slab", sans-serif;
	text-align: center;
}

.start-order > .start-order-find h4 {
	margin: 0 0 10px 0;
	color: var(--ion-color-tertiary);
	text-align: center;
}

.start-order > .start-order-pic {
	background-repeat: no-repeat;
	background-size: 100% 101%;
	background-position: center center;
	flex: 1 1 330px
}

.start-order-input {
	border: 1px solid var(--ion-color-black);
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative; 
	margin: 0;
	height: 50px;
}

.start-order-input ion-input {
	--color: var(--ion-color-black);
	--placeholder-color: var(--ion-color-medium-shade);
	--padding-start: 10px;
	--padding-top: 10px;
	--padding-bottom: 10px;
	height: 100%;
}

.start-order-input ion-button {
	margin: 0;
	height: 100%;
	--border-radius: 0;
}

.start-order-button {
	--border-radius: 0;
	margin: 25px 0 20px 0;
	width: 100%;
}

.start-order-button:last-of-type {
	margin-top: 20px 0px 0px 0px;
}

.start-order-button.uber-eats {
	margin: 0;
}

@media screen and (min-width: 768px) {
	.welcome div:first-child {
		padding-bottom: 10%;
		background-size: 70% auto;
	}
	/* .dash-grid > div .dash-grid-bg {
		padding-top: 74%;
		background-position: 50% 50%;
		background-size: auto 60%;
		background-repeat: no-repeat;
	}

	.dash-grid > div .dash-grid-label {
		font-size: 1.2vw;
	} */
	.start-order {
		/* flex-flow: row; */
		/* padding: 25% 2%; */
		/* width: 80vw; */
		display: grid;
		grid-template-columns: 400px 400px;
		grid-template-rows: 350px;
	}

	.start-order > .start-order-find,
	.start-order > .start-order-pic {
		/* flex: 1; */
		/* width: 50%; */
	}

	.start-order-input ion-input {
		--padding-start: 15px;
		--padding-top: 15px;
		--padding-bottom: 15px;
	}
}

@media screen and (min-width: 992px) {
	.start-order {
		/* width: 70vw; */
	}
}

@media screen and (min-width: 1200px) {
	.start-order {
		/* width: 60vw; */
	}
}

@media screen and (min-width: 1441px) {
	.start-order {
		/* flex-flow: row; */
		/* padding: 5% 25%; */
		/* width: 60vw; */
		/* height: 45vh; */
		display: grid;
		grid-template-columns: 500px 500px;
		grid-template-rows: 450px;
	}

	.start-order > .start-order-find {
		justify-content: center;
	}

	.start-order > .start-order-find h2 {
		/* margin-top: 10%; */
		font-size: 2.5rem;
	}

	.start-order-input {
		width: 70%;
	}

	.start-order-button {
		/* margin: 40px 0; */
	}

	.start-order-button:last-of-type {
		margin-bottom: 0px;
	}
}

@media screen and (min-width: 1921px) {
	.start-order > .start-order-find,
	.start-order > .start-order-pic {
		padding: 30px 40px;
	}
}
/* .dash-grid-padding {
	padding: 25px;
}

.welcome div:first-child {
	--background: #fff;
	padding-bottom: 15%;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 90% auto;
	box-shadow: none;
}

.dash-grid > div .dash-grid-bg {
	padding-top: 74%;
	background-position: 50% 50%;
	background-size: auto 90%;
	background-repeat: no-repeat;
}

.dash-grid > div .dash-grid-label {
	position: absolute;
	left: 5px;
	right: 5px;
	bottom: 5px;
	font-family: var(--ion-font-family);
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	font-size: 3vw;
	font-size: calc(50% + 1.5vw);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.dash-grid > div:first-child .dash-grid-bg {
	padding-top: 37%;
} */
.content-spinner {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.3);
}


.loader {
    color: var(--ion-color-white);
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    transform: translateZ(0);
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  }

  .transparent {
    background: rgba(0,0,0,0);
  }
  @keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes round {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.mbsc-mobiscroll .mbsc-fr-hdr, .mbsc-mobiscroll .mbsc-fr-btn {
	color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-cal-days > div {
	color: var(--ion-color-primary);
	border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt, .mbsc-mobiscroll.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover, .mbsc-mobiscroll .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
	background: var(--ion-color-primary-tint);
}

.mbsc-mobiscroll .mbsc-fr-btn.mbsc-active, .mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
	background: var(--ion-color-primary-tint);
}

.mbsc-mobiscroll .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	background: var(--ion-color-primary);
}

.date-picker-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-bottom: 1px solid rgba(0, 0, 0, 0.13);
	border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.date-picker-label {
	font-size: 12px;
	color: var(--okx-normal-text-color);
	color: #959595;
	margin-top: 10px;
	margin-left: 2px;
	transition: .1s ease-in;
}

.date-picker-label--primary {
	color: var(--ion-color-primary);
}

.data-picker-input {
	border: 0;
	outline: 0;
	color: var(--ion-color-dark);
	padding: 10px 8px 10px 0;
}


.mbsc-material .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	/* background: var(--ion-color-primary); */
	background: rgba(255, 26, 75, .9);
	color: #fff;
}

.mbsc-material .mbsc-fr-btn {
	color: var(--ion-color-primary);
}

.mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
	background: var(--ion-color-primary);
	border-color: var(--ion-color-primary);
}

.mbsc-mobiscroll .mbsc-sc-whl-l {
	display: none;
}
.mbsc-comp.time-picker {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
  outline: none;
}

.mbsc-comp.time-picker::-webkit-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker:-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-comp.time-picker::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-sc-itm-sel, .mbsc-ios .mbsc-sc-whl-gr-3d {
  /* color: #000; */
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-material .mbsc-sc-lbl {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-fr-btn1 .mbsc-fr-btn-e .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

/* .mbsc-sc-itm .mbsc-btn-e .mbsc-sc-itm-sel {
  color: var(--ion-color-primary,#3880ff);
} */

.mbsc-material .mbsc-fr-btn-cont .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
}

.mbsc-fr-btn-cont {
  display: flex !important;
  justify-content: space-between;
}

.mbsc-sc-itm-sel {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
  font-weight: 600;
}

.time-picker-label {
  font-size: 12px;
  color: var(--ion-color-primary);
  margin-top: 10px;
  margin-left: 2px;
}

.mbsc-mobiscroll .mbsc-sc-lbl {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff); 
}

.mbsc-material .mbsc-sc-whl-l {
  display: none;
}

.mbsc-select-input.mbsc-control {
  width: 100%;
  color: var(--ion-color-dark);
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
  outline: none;
}

.mbsc-select-input.mbsc-control::-webkit-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control:-ms-input-placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-select-input.mbsc-control::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-fr-btn {
  color: #3880ff;
  color: var(--ion-color-primary,#3880ff);
  font-size: 17px;
}

.select-picker-label {
  display: block;
  font-size: 12px;
  color: var(--ion-color-primary);
  margin-top: 10px;
  margin-left: 2px;
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border: 1px solid rgba(0,0,0,0.13);
  border: 1px solid var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: #000;
  color: #3880ff !important;
  color: var(--ion-color-primary,#3880ff) !important;
}
.version-box {
    display: flex;
    justify-content: center;
}

.version-box p {
    font-weight: bold;
}

.email-opt-item {
    --inner-padding-end: 0;
}

.email-opt-toggle > h2 {
    margin: 20px 0 5px 0;
    font-size: 1.4rem;
    color: var(--ion-color-primary);
    font-weight: 300;
}

.email-opt-toggle > p {
    margin: 0;
    font-size: 0.9rem;
    color: var(--ion-color-medium);
}


.desktop-profile-wrapper {
	flex: 1 1 auto;
	max-width: 90%;
	width: 100%;
	background: var(--ion-color-white);
	align-self: center;
	margin: 50px 0;
	padding: 40px 0;
}

.desktop-profile-wrapper ion-list {
	width: 75%;
	margin: 0 auto;
}

.desktop-profile-wrapper ion-button {
	width: 50%;
	margin: 0 auto;
}

.desktop-profile-header {
	color: var(--ion-color-black);
	text-align: center;
	font-size: 2rem;
	text-transform: uppercase;
	margin-bottom: 10px;
	font-family: 'Klinic Slab';
}

@media screen and (min-width: 768px) {
	.desktop-profile-wrapper {
		max-width: 70%;
	}

	.desktop-profile-wrapper ion-list {
		width: 65%;
	}

	.desktop-profile-header {
		font-size: 2.5rem;
	}
}

@media screen and (min-width: 992px) {
	.desktop-profile-wrapper {
		max-width: 50%;
	}

	.desktop-profile-wrapper ion-button {
		width: 35%;
	}
}

@media screen and (min-width: 1441px) {
	.desktop-profile-wrapper {
		flex: unset;
		max-width: 35%;
	}
}
.basket-content {
    /* padding: 20px; */
}

.basket-header {
    display: flex;
    justify-content: center;
    background-color: var(--ion-color-tertiary);
    /* padding: 10px; */
}

.basket-header ion-label {
    color: white;
    /* width: 150px; */
    font-weight: bold;
    text-align: center;
}

.basket-header-empty {
    /* margin-top: 40px; */
    text-align: center;
}

.basket-header-empty ion-label {
    font-size: 25px;
}

.basket-title {
    display: flex;
    justify-content: center;
    font-size: 24px;
}

.basket-buttons {
    padding: 15px 40px 15px 40px;
    background-color: white;
    border-top: 1px solid lightgray;
    /* position: fixed; */
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.basket-total {
    /* margin-top: 40px;
    margin-bottom: 200px; */
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    padding-right: 32px;
}

.basket-sub-items{
    margin: 0 0 0 30px;
}

.basket-item{
    width: 100%;
}

.basket-item-content {
    width: 100%;
}

.basket-subItem-content {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.item-label ion-label {
    text-overflow: unset; 
    white-space: unset;
    overflow: unset;
    flex: 2 1;
}

.basket-item-content ion-label {
    padding: 0;
    margin: 0 0 0 10px;
}

.basket-item-content ion-icon {
    padding: 0;
    margin: 0;
}

.basket-item-content ion-item {
    height: 35px;
}

.basket-item-content p {
    padding: 0;
    margin: 0;
    flex:1 1;
    display: flex;
    justify-content: flex-end;
}

.basket-total ion-label {
    font-size: inherit;
    padding-left: 5px;
    --color: initial;
}

.basket-total-price p {
    font-size: inherit;
    color: #000;
    color: initial;
    margin: 0;
    padding: 0;
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	.basket-buttons {
		transform: translate3d(0,0,0);
		bottom: var(--ion-tab-bar-height);
    }
    .basket-total {
        padding-right: 17px;
    }
}

.basket-special-notes ion-label{
    /* font-size: 13px; */
    margin: 0 0 0 30px;
    font-weight: 500;
    color: #000;
}

.basket-special-notes p{
    /* font-size: 13px; */
    margin: 0 0 0 30px;
    color: #000;
}

.basket-sub-items ion-item {
    height: 25px;
    display: flex;
    align-items: center;
}
.desktop-basket-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: column;
		flex: 1 1 auto;
    max-width: 90%;
    width: 100%;
    background: var(--ion-color-white);
    align-self: center;
    margin: 50px 0;
		padding: 40px 0 0 0;
		position: relative;
}

.desktop-basket-header {
	color: var(--ion-color-black);
	text-align: center;
	font-size: 2rem;
	text-transform: uppercase;
	margin-bottom: 10px;
	font-family: 'Klinic Slab';
	flex-grow: 0;
}

.basket-buttons {
	/* position: relative; */
	flex-grow: 0;
	padding: 15px 0;
}

.basket-header-empty {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.basket-header-empty ion-label {
	color: var(--ion-color-black);
	font-family: "Klinic Slab";
}

.desktop-basket-inner {
	width: 85%;
	margin: 0 auto;
	flex: 1 1 auto;
	display: flex;
	flex-flow: column;
}

.basket-header {
	padding: 15px;
}

.basket-header ion-label {
	width: 100%;
	text-transform: uppercase;
	text-align: center;
	font-size: 1.2rem;
}

.basket-content {
	padding: 0;
	flex: 1 1 auto;
}

.basket-title {
	color: var(--ion-color-black);
	margin: 20px 0 0 0;
}

.basket-total {
	margin-top: 40px;
	margin-bottom: 40px;
	padding-right: 16px;
}

.basket-total div {
	color: var(--ion-color-black);
	font-weight: 500;
}

.basket-item-content ion-item{
	--inner-padding-end: 0;
}

.basket-item-content ion-label {
	line-height: 2rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.9rem!important;
}

.basket-item-content p {
	font-weight: 500;
	line-height: 2rem;
	font-size: 0.9rem;
}

@media screen and (min-width: 768px) {
	.desktop-basket-wrapper {
		max-width: 70%;
	}

}

@media screen and (min-width: 992px) {
	.desktop-basket-wrapper {
		max-width: 50%;
	}

	.basket-buttons ion-button {
		width: 35%;
		margin: 0 auto;
	}
	
	.basket-buttons.not-empty-basket {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
	}
	
	.basket-buttons.not-empty-basket ion-button {
		width: 100%;
		margin: 0 auto;	
	}

	.basket-buttons.not-empty-basket ion-button:last-of-type {
		grid-column-end: span 2;
		width: 50%;
	}

}

@media scren and (min-width: 1441px) {
	.desktop-basket-wrapper {
		max-width: 35%;
	}
}
/* .desktop-basket-wrapper {
	display: unset;
}

.desktop-basket-inner {
	width: auto;
}

.basket-content {
	padding: 20px
}

.basket-buttons {
	padding: 15px 40px 15px 40px;
	position: fixed;
}

.basket-header-empty {
	margin-top: 40px;
}

.basket-header-empty ion-label {
	color: inherit;
	font-family: inherit;
}

.basket-header {
	padding: 10px;
}

.basket-header ion-label {
	width: 150px;
	text-transform: inherit;
	text-align: unset;
	font-size: inherit;
}

.basket-title {
	color: inherit;
	margin: inherit;
}

.basket-total {
	margin-top: 40px;
	margin-bottom: 200px;
	padding-right: inherit;
}

.basket-item-content ion-item{
	--inner-padding-end: inherit;
}

.basket-item-content ion-label {
	line-height: 2rem;
	text-transform: inherit;
	font-weight: inherit;
	font-size: inherit;
}

.basket-item-content p {
	font-weight: inherit;
	line-height: 2rem;
	font-size: inherit;
}

.basket-special-notes ion-label,
.basket-special-notes p {
	font-size: 13px;
} */
.simple-loader {
	display: flex;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 6;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: rgba(0,0,0,0.3);
}

.loader {
  color: var(--ion-color-white);
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  transform: translateZ(0);
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.transparent-loader {
	display: flex;
	width: 210px;
	height: 100px;
	position: absolute;
	z-index: 6;
	justify-content: space-between;
	align-items: center;
	background: white;
	border-radius: 10px;
	padding: 0 15px
}

.message {
	margin-left: 15px;
}

.message h2 {
	font-size: 16px;
	color: #000;
	color: initial;
}
.swiper-container {
	position: static;
}

.inner-scroll.scroll-y {
	/*background-color: transparent !important;*/
	--background: transparent;
}

.stamp-grid-padding {
	padding: 5%;
	padding-top: 10px;
	padding-bottom: 0px;
}

.swiper-holder {
	position: relative;
}

.swiper-item {
	display: flex;
	flex-direction: column;
	background-color: rgba(255,255,255,0.7);
	width: 100%;
	height: 100%;
	border-radius: 10px;
	/* margin: 20px; */
}

.swiper-items {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: 20px;
}

.swiper-items > .swiper-item {
	margin: 0;
}

.swiper-header {
	background-color: var(--ion-color-tertiary);
	padding: 15px;
	color: #fff;
	text-transform: uppercase;
	/* border-top-left-radius: 10px;
	border-top-right-radius: 10px; */
}

.stamp-header {
	color: var(--ion-color-secondary) !important;
	font-family: "Klinic Slab";
	font-size: 20px;
	margin-bottom: 0px;
	margin-top: 10px;
}

.stamp-note {
	color: var(--ion-color-secondary) !important;
	font-family: "Klinic Slab";
	font-size: 11px;
	line-height: 12px;
	margin-bottom: 0px;
	margin-top: 0px;
}

.stamp-note.bold {
	font-weight: bold;
	text-transform: none !important;
	margin-top: 2px;
	margin-left: 20px;
	margin-right: 20px;
}

.stamp-note span {
	text-decoration: underline;
}

.swiper-content {
	padding: 10px;
	padding-top: 0px;
	overflow-y: auto;
	color: var(--ion-color-dark);
}

.swiper-content p {
	text-transform: uppercase;
	font-weight: 500;
	margin-top: 10px;
}

.swiper-wrapper {
	position: absolute;
	top: 20px;
	bottom: 50px !important;
	left: 0;
	height: auto;
	bottom: 0;
}

/* .horizontal-slider .swiper-wrapper {
	padding-left: 15px;
}
.horizontal-slider .swiper-wrapper ion-slide {
	width: 115px;
	margin-right: 10px;
} */

.swiper-wrapper ion-button {
	margin: 20px;
}

.loyalty-arrow-right {
	position: absolute;
	color: var(--ion-color-white);
	opacity: 1;
	z-index: 3;
	font-size: 60px;
	right: -45px;
	top: 45%;
	transform: translateY(-50%);
	cursor: pointer;
}

.loyalty-arrow-left {
	color: var(--ion-color-white);
	opacity: 1;
	z-index: 3;
	font-size: 60px;
	position: absolute;
	top: 45%;
	left: -45px;
	transform: translateY(-50%);
	cursor: pointer;
}
.loyalty-arrow-right.disabled,
.loyalty-arrow-left.disabled {
	opacity: 0.4;
	cursor: default;
	pointer-events: none;
}

.qr-holder {
	margin: 10px auto;
}

.loyalty-grid-padding {
	padding: 20%;
	padding-top: 10px;
}


.loyalty-grid {
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: repeat(4, 1fr);
	grid-template-areas: 
		"one two three four"
		"five six seven eight"
		". nine ten ."
	;
	grid-row-gap: 25px;
	grid-column-gap: 5px;
	justify-items: center;
	/* margin: 0 30px; */
}

.loyalty-grid-stamp {
	position: relative;
	width: 80%;
	color: var(--ion-color-medium);
	/* font-size: 5vw; */
	display: flex;
    justify-content: center;
    align-items: center;
	border-radius: 50%;
	background-color: #fff;
}

.loyalty-grid-stamp::after {
    display: block;
    content: '';
    padding-top: 100%;
}

.loyalty-grid-stamp::before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
    content: '';
	background-repeat: no-repeat;
	border-radius: 0;
}

.loyalty-grid-stamp.stamp1::before {
	background-image: url(/static/media/1.fb20f935.png);
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: 40% 50%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp2::before {
	background-image: url(/static/media/2.1d9e982e.png);
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: 70% 50%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp3::before {
	background-image: url(/static/media/3.23e7fd20.png);
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: 60% 60%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp4::before {
	background-image: url(/static/media/4.5e2ae16f.png);
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: 50% 50%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp5::before {
	background-image: url(/static/media/5.c373b9e7.png);
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: 50% 50%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp6::before {
	background-image: url(/static/media/6.33460a34.png);
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: 80% 60%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp7::before {
	background-image: url(/static/media/7.f80a2fec.png);
	background-repeat: no-repeat;
	background-size: 110% 110%;
	background-position: 50% 50%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp8::before {
	background-image: url(/static/media/8.7f2a62a0.png);
	background-repeat: no-repeat;
	background-size: 110% 110%;
	background-position: 70% 60%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp9::before {
	background-image: url(/static/media/9.25f410bc.png);
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: 50% 50%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp10::before {
	background-image: url(/static/media/10.5fe0c3ec.png);
	background-repeat: no-repeat;
	background-size: 120% 120%;
	background-position: 45% 45%;
	overflow: visible;
}

.loyalty-grid-stamp.stamp1,
.loyalty-grid-stamp.one {
	grid-area: one;
}

.loyalty-grid-stamp.stamp2,
.loyalty-grid-stamp.two {
	grid-area: two;
}

.loyalty-grid-stamp.stamp3,
.loyalty-grid-stamp.three {
	grid-area: three;
}

.loyalty-grid-stamp.stamp4,
.loyalty-grid-stamp.four {
	grid-area: four;
}

.loyalty-grid-stamp.stamp5,
.loyalty-grid-stamp.five {
	grid-area: five;
}

.loyalty-grid-stamp.stamp6,
.loyalty-grid-stamp.six {
	grid-area: six;
}

.loyalty-grid-stamp.stamp7,
.loyalty-grid-stamp.seven {
	grid-area: seven;
}

.loyalty-grid-stamp.stamp8,
.loyalty-grid-stamp.eight {
	grid-area: eight;
}

.loyalty-grid-stamp.stamp9,
.loyalty-grid-stamp.nine {
	grid-area: nine;
}

.loyalty-grid-stamp.stamp10,
.loyalty-grid-stamp.ten {
	grid-area: ten;
}

.loyalty-grid-palm {
	height: 100%;
	width: 100%;
	background-image: url(/static/media/palm.dcd0f977.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 50%;
}

.stamped {
	color: var(--ion-color-secondary);
	/* font-size: 2vw; */
	font-family: "Klinic Slab";
}

.reward-image {
	width: 100%;
	padding-bottom: 40%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 100% auto;
}

.modal-content ion-button {
	width: 100%;
}

.modal-content ion-toolbar {
	text-transform: uppercase;
	font-weight: normal !important;
	font-size: 18px;
}

.modal-title {
	text-transform: uppercase;
}

.voucher-code ion-item {
	margin: 0 18px;
}

.box-padded {
	padding: 10px 20px;
}

.loyalty-modal > .modal-wrapper {
	--max-height: 270px;
	--max-width: 280px;
	--border-radius: 15px;
}

.loyalty-modal > .modal-wrapper > .ion-page {
	justify-content: space-between;
}

.ion-text-center p {
	margin: 5px 0px 0px 0px;
}
/* 
ion-title.ion-text-center.modal-title {
	padding: 0px 20px 0px 0px;
} */

.loyalty-modal > .modal-wrapper > .ion-page ion-button {
	padding: 0 20px;
}

ion-buttons.close ion-button {
	display: flex;
	justify-content: flex-end;
	
}

.loyalty-modal > .modal-wrapper > .ion-page ion-buttons > ion-button  {
	padding: 0px;
}
.desktop-loyalty-wrapper {
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	padding: 3vw 10vw;
}

.desktop-loyalty-header {
	background: var(--ion-color-white);
	color: var(--ion-color-black);
	font-size: 1.8rem; /* 2.5 */
	text-transform: uppercase;
	text-align: center;
	padding: 20px;
	margin-bottom: 30px;
	font-weight: 500;
	font-family: "Klinic Slab";
}

.desktop-loyalty {
	display: flex;
	flex: 1 1 auto;
	flex-flow: column;
}

.loyalty-grid {
	margin: 0;
}

.loyalty-grid-stamp {
	font-size: 6vw;
	background-color: var(--ion-color-tertiary);
	color: var(--ion-color-white);
}

.loyalty-grid-stamp.stamped {
	font-size: 2vw;
	background-color: #69d1ea;
	color: var(--ion-color-white);
}

.loyalty-arrow {
	right: -10%;
}

.swiper-item {
	margin: 0;
}

.swiper-header {
	text-align: center;
	font-size: 20px;
	padding: 10px;
	font-weight: 700;	
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.stamp-header {
	text-align: center;
}

.swiper-content {
	background-image:url(/static/media/rewards-box-back.dd607bcd.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.swiper-content p{
	text-align: center;
}

.desktop-loyalty-swiper {
	position: relative;
	height: 400px;
}

.desktop-loyalty-swiper.no-vouchers {
	display: flex;
	justify-content: center;
	align-items: center;
}

.desktop-loyalty-swiper.no-vouchers h2 {
	color: var(--ion-color-black);
	text-transform: uppercase;
}

.desktop-loyalty-swiper > ion-slides {
	position: relative;
	height: 100%;
}

@media screen and (min-width: 768px) {
	.loyalty-grid-stamp {
		font-size: 5vw;
	}

	.loyalty-grid {
		/* grid-template-columns: repeat(3, 110px); */
		justify-content: center;
	}
	

	.swiper-item {
		width: 70%;
		align-self: center;
	}

	.loyalty-arrow-right {
		right: 50px;
	}

	.loyalty-arrow-left {
		left: 50px;
	}

}

@media screen and (min-width: 992px) {

	.desktop-loyalty-header {
		font-size: 2.5rem;
	}

	.swiper-item {
		width: 90%;
	}

	.desktop-loyalty {
		flex-flow: row;
	}

	.desktop-loyalty-swiper {
		height: auto;
		width: 100%;
	}

	.loyalty-grid {
		/* grid-template-columns: repeat(3, 130px); */
	}

	.loyalty-grid-stamp {
		font-size: 3vw;
	}

	.loyalty-grid-stamp.stamped {
		font-size: 1.5vw;
		text-align: center;
	}
	.loyalty-arrow-right {
		right: 0;
	}

	.loyalty-arrow-left {
		left: 0;
	}
}

@media screen and (min-width: 1441px) {
	.loyalty-grid-stamp {
		font-size: 2vw;
		/* background-color: var(--ion-color-tertiary);
		font-weight: bold;
    	color: white; */
	}

	.loyalty-grid-stamp.stamped {
		font-size: 0.8vw;
		/* font-size: 2vw; */
		/* background-color: #69d1ea;
		font-weight: bold;
    	color: white; */
	}

	/* .loyalty-grid-stamp.stamp10.stamped {
		background-color:#1ebbb4;
	} */

	.loyalty-grid {
		grid-template-columns: repeat(4, 170px);
		/* grid-template-rows: repeat(3, 170px); */
		justify-content: center;
	}

	/* .loyalty-grid:last-of-type {
			grid-column: 2 / span 1;
			width: 130px;
			height: 130px;
	} */

	.desktop-loyalty-header {
		font-size: 2.5rem;
	}
}
/* .swiper-item {
	margin: 20px
}

.swiper-header {
	padding: 15px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.loyalty-grid {
	margin: 0 30px;
}

.loyalty-grid-stamp {
	font-size: 5vw;
}

.loyalty-grid-stamp.stamped {
	font-size: 2vw;
}

.loyalty-arrow {
	right: 5%;
} */
.pick-time-modal .modal-wrapper {
	width: 80vw;
	height: 35vh;
}

@media screen and (max-width: 360px) {
	.pick-time-modal .modal-wrapper {
		width: 100%;
		margin: 20px;
	}
}

@media screen and (max-width: 510px) {
	.item-name {
		padding: 0px;
		font-size: 20px !important;
	}

	.buttons-first-slot{
		width: 0px !important;
	}
}

@media screen and (min-width: 990px) and (max-width: 1215px) {
	.item-name {
		padding: 0px;
		font-size: 20px !important;
	}

	.buttons-first-slot{
		width: 0px !important;
	}
}


.pick-time-close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 2rem;
	cursor: pointer;
}

.pick-time-modal-inner {
	position: relative;
	display: flex;
	flex: 1 1 auto;
	flex-flow: column;
	justify-content: center;
	width: 80%;
	margin: 0 auto;
}

.pick-time-modal-inner h2 {
	text-transform: uppercase;
	font-family: 'Klinic Slab';
	text-align: center;
	font-weight: 500;
}

.pick-time-modal-inner ion-button {
	--border-radius: none;
	margin-top: 20px;
}

.desktop-date-picker > .picker-wrapper {
	position: absolute;
	top: 0;
	width: 60%;
	height: 35%;
}

.desktop-date-picker > .picker-wrapper > .picker-toolbar {
	justify-content: space-between;
}

.desktop-date-picker > .picker-wrapper > .picker-columns {
	margin: 0;
}

.desktop-date-picker > .picker-wrapper > .picker-columns > ion-picker-column > .picker-opts{
	/* top: 15px */
}

@media screen and (min-width: 992px) {
	.pick-time-modal .modal-wrapper {
		width: 30vw;
	}

	.pick-time-modal-inner h2 {
		margin-bottom: 30px;
	}

	.desktop-date-picker > .picker-wrapper {
		width: 25%;
	}
}

@media screen and (min-width: 1441px) {
	.pick-time-modal .modal-wrapper {
		height: 30vh;
	}

	.desktop-date-picker > .picker-wrapper {
		width: 15%;
		height: 25%;
	}
}
.gm-holder {
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
}

.map-holder {
	position: relative;
	height: 100%;
}

.map {
    width: 100%;
    height: 100%;
}

.map-location-holder {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    top: 60%;
    background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.gm-style .map-location-holder {
	z-index: 9999999;
}

.location-section ion-item {
    --color: var(--ion-color-dark);
    --padding-start: 0px;
    --border-color: var(--ion-separator-color);
}

.location-section p {
    color: var(--ion-color-dark);
    font-size: 13px;
    line-height: 20px;
}

.location-info {
    font-size: 11px;
    line-height: 20px;
}

ion-list.location-section ion-item:last-child {
    --padding-start: 0px;
    --border-width: 0px;
    --border-color: #fff;
}

.show-marker-info {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.show-marker-info p {
    font-weight: 500;
    margin: 0;
    color: var(--ion-color-black);
    text-align: center;
}

.show-marker-info .show-marker-info-address {
    margin: 10px 0;
}
.map-wrapper {
	flex: 1 1 auto;
	display: flex;
	flex-flow: column;
	margin: 3% 10%;
}

.desktop-locations-title {
	display: flex;
	justify-content: center;
	background: var(--ion-color-white);
	margin: 6% 10% 3% 10%;
}

.desktop-locations-title h2 {
	color: var(--ion-color-black);
	margin: 20px;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Klinic Slab';
	font-size: 1.8rem;
	font-weight: 500;
}

.map-wrapper > div:first-of-type {
	position: relative;
}

.map-wrapper > div:first-of-type .stores-list {
	width: 100%;
	height: 100%;
	display: flex;
	overflow-x: auto;
	padding: 0;
	margin: 0 0 25px 0;
	background-color: transparent;
}

.map-wrapper > div:first-of-type .stores-list .store {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	display: flex;
	flex-flow: column;
	align-items: center;
	min-width: 100%;
	width: 100%;
	--min-height: 100%;
	--inner-padding-end: 0;
	margin-right: 20px;
	/* padding: 25px 0; */
}

.map-wrapper > div:first-of-type .stores-list .store:last-of-type {
	margin-right: 0;
}

.map-wrapper > div:first-of-type .stores-list .store h4,
.map-wrapper > div:first-of-type .stores-list .store h1,
.map-wrapper > div:first-of-type .stores-list .store p {
	margin: 0;
	color: var(--ion-color-white);
	text-transform: uppercase;
	text-align: center;
}

.map-wrapper > div:first-of-type .stores-list .store h1,
.map-wrapper > div:first-of-type .stores-list .store h4 {
	font-weight: 700;
	font-family: "Klinic Slab";
}

.map-wrapper > div:first-of-type .stores-list .store h4 {
	margin-bottom: 5px;
}
.map-wrapper > div:first-of-type .stores-list .store ion-icon {
	margin: 7px 0;
}

.map-wrapper > div:first-of-type .stores-list .store p {
	font-size: 13px;
	font-weight: 500;
}

.map-wrapper > div:first-of-type .stores-list .store p.store-address {
	margin: 5px 0 15px 0;
}

ion-button.order-now {
	margin-top: 50px;
	--border-radius: 0;
	--border-width: 1px!important;
	--border-color: var(--ion-color-white)!important;
	--padding-start: 45px;
	--padding-end: 45px;
	--color-activated: var(--ion-color-white);	
	--color-focused: var(--ion-color-white);	
	--color-hover: var(--ion-color-white);
	text-transform: none !important;
}

.map-wrapper > div:first-of-type .stores-list .store .store-info {
	flex: 1 1 auto;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 25px 10px;
}

.map-wrapper > div:first-of-type .stores-list .store .store-dist {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px 0;
	border-top: 1px solid var(--ion-color-white);
}

.map-wrapper > div:first-of-type .stores-list .store .store-dist p {
	text-transform: inherit;
}

.map-wrapper > div:first-of-type .stores-list .store .store-dist .distance {
	margin: 0 10px;
}

.desktop-map {
	position: -webkit-sticky;
	position: sticky;
	flex: 1 1 auto;
	height: 300px;
	top: 0;
}

.web-locations-arrow {
	position: absolute;
	bottom: 20px;
	right: 5px;
	top: 50%;
	color: #fff;
	font-size: 2rem;
}

@media screen and (min-width: 992px) {
	.desktop-locations-title {
		margin: 3% 10% 0 10%;
	}

	.desktop-locations-title h2 {
		font-size: 2.5rem;
	}

	.map-wrapper {
		flex-flow: row;
		margin: 3% 10%;
	}

	.map-wrapper > div:first-of-type {
		margin-right: 30px;
	}

	.map-wrapper > div:first-of-type .stores-list {
		flex-flow: column;
		margin: 0;
	}

	.map-wrapper > div:first-of-type .stores-list .store {
		margin-bottom: 30px;
		margin-right: 0;
		/* padding: 25px; */
	}

	.map-wrapper > div:first-of-type .stores-list .store:last-of-type {
		margin-bottom: 0;
	}

	.web-locations-arrow {
		display: none;
	}
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
	.desktop-map {
		height: 600px;
	}
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
	.desktop-map {
		height: 800px;
	}

	.desktop-locations-title h2 {
		font-size: 2.5rem;
	}
}

@media screen and (min-width: 1921px) {
	.desktop-map {
		height: 1100px;
	}

	.desktop-locations-title h2 {
		font-size: 2.5rem;
	}
}
/* .desktop-map {
	position: initial;
	flex: initial;
} */


.feedback-label {
    margin: 5px;
}

.star-holder {
  padding: 10px 0px;
}

.star {
  font-size: 26px;
  color: var(--ion-feedback-star);
  margin-right: 15px;
}
  
.refer-holder ion-item {
  --border-color: var(--ion-separator-color) !important;
}

.social-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.palm {
    width: 30%;
	--background: #fff;
	padding-bottom: 100%;
	background-image: url(/static/media/palm.dcd0f977.png);
	background-repeat: no-repeat;
	background-size: auto 100%;
}

.palm-left {
	background-position: 60% -30px;
}

.palm-right {
	background-position: 35% -30px;
}

.content-title {
    margin-top: 30px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 18px;
    color: var(--ion-color-tertiary);
}

.social-button {
    letter-spacing: 2px;
    text-transform: uppercase;
    border-radius: 500px;
	box-shadow: 0 2px 30px 0px rgba(0,0,0,0.5);
}

.social-title {
    font-size: 20px;
    color: var(--ion-color-dark);
    margin-bottom: 20px;
}

@media only screen and (max-width: 450px) {
    .palm-left {
        background-position: 65% -30px;
    }
    
    .palm-right {
        background-position: 30% -30px;
    }
}

.radio-pic {
	--background: #fff;
	padding-bottom: 50%;
	background-image: url(/static/media/island_radio.f29ea3e2.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
    background-size: contain;
}

.radio-holder {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.bottom-button-holder {
	position: fixed;
	left: 20px;
	right: 20px;
	bottom: 20px;
	color: #1db954;
}


@media only screen and (max-width: 1200px) {
	.radio-pic {
		padding-bottom: 80%;
	}
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	.bottom-button-holder  {
        bottom: var(--ion-tab-bar-height);
        transform: translate3d(0,0,0)
	}
}
.allergen-holder {
    margin-top: 10px;
}

.allergen-holder h3 {
   margin-bottom: 0px;
   text-align: left;
   color: var(--ion-color-dark)
}

.allergen-holder > span > h3:first-child {
    margin-top: -10px;
}


ion-input.okx-password {
	flex-direction: row-reverse;
}

ion-input.okx-password ion-button {
	--background: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--background-hover: transparent;
	--ripple-color: transparent;
}

.tails {
	background-image: url(/static/media/login-bg.3a99f023.png);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.poster.login-01 {
	background-color: transparent;
	height: 11%;
	min-height: 50px;
}

/* .poster.login-01::after {
	content: '';
	display: block;
	height: 150px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background: inherit;
	background-image: url(../../assets/images/islandpoke_logo_hero.jpg);
	background-color: transparent;
	background-position: 50% 40%;
	background-size: 60% auto;
	background-repeat: no-repeat;
	z-index: 2;
} */

.login-icon {
	margin-right: 10px;
	margin-top: 40px;
	color: var(--ion-color-primary);
}

.login-button {
	margin-top: 40px;
	--border-width: 5px;
	border-right: 2px solid;
	border-left: 2px solid;
}

.text-link {
	margin-top: 0;
	height: 20px;
	width: 100%;
	margin-bottom: 20px;
}

.text-link:last-of-type {
	margin-bottom: 0;
}
/* 
.login-header {
	margin-top: 15%;
	color: var(--ion-color-tertiary);
} */


.poster.login-01::after {
	content: '';
	display: block;
	height: 140px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background: inherit;
	background-image: url(/static/media/islandpoke_logo_hero.7f274220.jpg);
	background-color: transparent;
	background-position: 50% 40%;
	background-size: 90% auto;
	background-repeat: no-repeat;
	z-index: 2;
}

.login-header {
	margin: 0;
	color: var(--ion-color-tertiary);
}

.login-holder {
	background-color: var(--ion-color-white);
	padding: 20px;
	margin: 50px 0;
	max-width: 90%;
	width: 100%;
	align-self: center;
}

.login-holder-inner {
	max-width: 90%;
	margin: 0 auto;
}

@media screen and (min-width: 481px) {
	.poster.login-01::after {
		background-size: 70% auto;
	}
}

@media screen and (min-width: 768px) {
	.poster.login-01::after {
		background-size: 55% auto;
	}

	.login-register-buttons {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login-register-buttons ion-button {
		width: 50%;
	}

	.login-register-buttons ion-button:first-of-type {
		margin: 30px 10px 0 0;
	}

	.login-register-buttons ion-button:last-of-type {
		margin: 30px 0 0 10px;
	}
}

@media screen and (min-width: 992px) {
	.poster.login-01::after {
		background-size: 30% auto;
	}

	.login-header {
		margin-top: 5%;
	}

	.login-holder {
		/* flex: 1 1 auto; */
		margin: auto;
    max-width: 60vw;
    width: 100%;
	}
}

@media screen and (min-width: 1200px) {
	.login-holder {
		max-width: 45vw;
    width: 100%;
	}
	
	.login-holder-inner {
		max-width: 70%;
    margin: 0 auto;
	}
}

@media screen and (min-width: 1441px) {
	.login-holder {
		max-width: 35vw;
		width: 100%;
	}
	
}

@media screen and (min-width: 1921px) {
	.login-holder {
		max-width: 30vw;
		width: 100%;
	}
}
/* .poster.login-01::after {
	content: '';
	display: block;
	height: 150px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	background: inherit;
	background-image: url(../../assets/images/islandpoke_logo_hero.jpg);
	background-color: transparent;
	background-position: 50% 40%;
	background-size: 60% auto;
	background-repeat: no-repeat;
	z-index: 2;
}

.login-header {
	margin-top: 15%;
	color: var(--ion-color-tertiary);
} */
.blue-note {
	margin-top: 15%;
	color: var(--ion-color-primary);
}

.red-note {
	margin-top: 15%;
	color: var(--ion-color-tertiary);
}

.email-opt-item {
	--inner-padding-end: 0;
}

.email-opt-toggle > h2 {
	margin: 20px 0 5px 0;
	font-size: 1.4rem;
	color: var(--ion-color-primary);
	font-weight: 300;
}

.email-opt-toggle > p {
	margin: 0;
	font-size: 0.9rem;
	color: var(--ion-color-medium);
}
.register-wrapper {
	margin: 50px 0;
	max-width: 90%;
	width: 100%;
	align-self: center;
	background: var(--ion-color-white);
	padding: 40px 0;
}

.register-wrapper ion-list{
	width: 75%;
	margin: 0 auto;
}

.register-wrapper .top-medium ion-button {
	margin: 0 auto;
	width: 50%;
}

.register-title {
	color: var(--ion-color-black);
	text-align: center;
	font-size: 2rem;
	text-transform: uppercase;
	margin-bottom: 10px;
	font-family: 'Klinic Slab';
}

@media screen and (min-width: 768px) {
	.register-wrapper {
		max-width: 70%;
	}

	.register-title {
		font-size: 2.5rem;
	}

	.register-wrapper ion-list {
		width: 65%;
	}
}

@media screen and (min-width: 992px) {
	.register-wrapper {
		max-width: 50%;
	}

	.register-wrapper .top-medium ion-button {
		width: 35%;
	}
}

.desktop-reset-wrapper {
	flex: 1 1 auto;
	max-width: 90%;
	width: 100%;
	background: var(--ion-color-white);
	align-self: center;
	margin: 50px 0;
	padding: 40px 0;
}

.reset-password-header {
	color: var(--ion-color-black);
	text-align: center;
	font-size: 2rem;
	text-transform: uppercase;
	margin-bottom: 10px;
	font-family: 'Klinic Slab';
}

.desktop-reset-inner {
	width: 90%;
	margin: 0 auto;
}

@media screen and (min-width: 768px) {
	.desktop-reset-wrapper {
		max-width: 70%;
	}

	.desktop-reset-inner {
		width: 80%;
	}

	.reset-password-header {
		font-size: 2.5rem;
	}
}

@media screen and (min-width: 992px) {
	.desktop-reset-wrapper {
		flex: unset;
		max-width: 35%;
	}
}

@media screen and (min-width: 1441px) {
	.desktop-reset-wrapper {
		max-width: 25%;
	}
}
.category-image {
    /* margin-bottom: 10px; */
    border-radius: 10px;
    width: 100%;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
}

.category-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    font-weight: bold;
    font-size: 20px;
    width: 100%;
}

.category-item {
    position: relative;
    text-align: center;
    color: white;
    /* padding: 0 30px; */
}

.desktop-categories-wrapper {
	margin: 0;
}

/* .category-item:nth-of-type(2) {
	margin: 0;
}

.category-item:nth-of-type(3) {
	margin: 0;
} */

.category-item {
	padding: 0 30px;
}

.category-image {
	margin-bottom: 10px;
}
/* .veritcal-item {
    margin-bottom: 20px;
} */

.veritcal-item-image {
    width: 100%;
    /* height: 110px; */
    border-radius: 10px;
    background-size: cover;
    background-position: center;
}

.subcategory-name {
    text-align: center;
    /* font-size: 18px;
    font-weight: bold; */
}

.vertical-item-name {
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
}
.vertical-item-price {
    text-align: center;
    color: var(--ion-color-primary);
    font-weight: 600;
}

.modal {
    /* --width: 90vw; */
    --max-height: 150vw;
    --border-radius: 10px;
}

ion-modal.modal-auto-height {
  --border-radius: 5px;
  --height: auto;
}

/* ion-modal.modal-auto-height .modal-wrapper {
  width: 90vw;
} */

ion-modal.modal-auto-height .modal-wrapper .ion-page {
  position: relative;
  contain: content;
  height: 100%;
}

/* ion-modal.modal-auto-height .modal-wrapper .ion-page .item-content {
  overflow: auto;
} */

/* .view-basket-button {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0px 20px;
    /* border-top: 1px solid lightgray; 
    background-color: white;
} */

.category-content{
    margin-bottom: 0px !important;
}

/* .items-list-content {
    margin-bottom: 100px;
} */

.item-image {
    width: 100%;
    height: 205px;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    margin-top: 35px;
}

/* .item-actions {
    padding: 20px;
} */

/* .item-actions-base {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 80px;
} */

.no-thanks-button {
    border: 2px solid var(--ion-color-primary);
}

.item-action{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.item-quantity {
    margin: 0 20px;
}

.item-price{
    text-align: center;
    color: var(--ion-color-primary);
    font-weight: 600;
}

/* .item-actions-header {
    text-align: center;
    font-size: 20px;
} */

.quantity-button {
    width: 75px;
}

/* .item-header {
    display: flex;
    margin-bottom: 10px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: white;
    z-index: 1;
} */

/* .item-name {
    text-transform: uppercase;
    margin: auto;
    margin-top: 10px;
    font-size: 20px;
} */

/* .item-close {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 30px;
} */

.item-description {
    padding: 10px;
    /* line-height: 2; */
    font-size: 20px;
    letter-spacing: 1px;
    height: 92px;
    overflow-y: auto;
}

ion-button.select-base-button {
    margin: 0;
    /* margin-bottom: -50px; */
}

/* .select-base {
    margin: 0;
    position: absolute;
    bottom:0;
    width: 100%;
    border-radius: 10px;
} */

/* .item-content {
    overflow-y: auto;
    margin-bottom: 50px;
} */

.horizontal-slider {
    /* height: 180px; */
    /* height: 195px; */
    position: relative;
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: 20px;
}

.item-slide-name {
    margin: 10px 0 0 0;
    /* font-size: 12px; */
    /* height: 20px; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px; /* WIDTH OF ITEM SLIDE IMAGE */
    text-align: center;
    /* font-size: 12px; */
    /* width: 100px; */
    height: 35px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
}

.category-title {
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    /* margin-bottom: 60px;
    margin-top: -20px; */
    /* margin-top: -20px; */
}

.category-title-byob {
    text-transform: uppercase;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0px;
    bottom: 195px;
    left: 0;
    border-top: 1px solid lightgray;
    right: 0;
    padding-top: 5px;
}

.item-slide-price {
    text-align: center;
    color: var(--ion-color-primary);
    font-size: 12px;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.item-slide-image {
    height: 100px;
    width: 100px;
    border-radius: 15px;
}

.item-slide-image-selected {
    background-color: var(--ion-color-primary);
    /* height: 100px;
    width: 100px; */
    border-radius: 15px;
    position: relative;
}

.item-slide-image img {
    border-radius: 15px;
    height: 100% !important;
    width: 100%;
}

.byob-header {
    height: 200px;
    width: 300px;
    margin: auto;
    /*margin-top: -35px;*/
    background-image: url(/static/media/byob-intro.7c7ab4a6.svg);
    background-repeat: no-repeat;
	background-size: contain;
	background-position: 50% 50%;
}

.selected-item-checkmark {
    height: 100px;
    width: 100px;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-item-checkmark ion-icon {
    font-size: 60px;
}

.byob-image {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
}

.top-image {
    height: 200px;
    width: 300px;
    background-size: cover;
    background-position: center;
}

.finish-image {
    position: absolute;
    top: 0;
    height: 200px;
    width: 300px;
    background-size: cover;
    background-position: center;
}

/* .item-third-header {
    display: flex;
    background-color: var(--ion-color-primary);
}

.item-third-header ion-label {
    text-transform: uppercase;
    color: white;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;
}
.item-third-header ion-icon {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 26px;
    color: white;
} */

.item-third-list {
    padding: 20px;
}

.additional-top-image {
    position: absolute;
    top: 0;
    height: 300px;
    width: 300px;
    background-size: cover;
    background-position: center;
}

/* .special-notes {
    padding: 20px;
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 40px;
    left: 0;
    background-color: white;
    z-index: 30;
    
} */

ion-list.list-md {
    padding-top: 1px;
    padding-bottom: 0px;
    line-height: 20px;
}

ion-list.list-ios {
    margin-top: 0px;
    margin-bottom: 0px;
}

/* .horizontal-slider-content-byob {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
} */

/* .select-base-content{
    height: 190px;
    overflow-y: auto;
} */

/* .arrow-down {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
} */
/* .list-header:first-of-type {
    margin-top: 25px;
} */

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	.view-basket-button  {
        bottom: var(--ion-tab-bar-height);
        transform: translate3d(0,0,0)
    }
    .special-notes {
        z-index: 30;
    }
}

.view-basket-button {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 20px;
	border-top: 1px solid lightgray;
	background-color: white;
}

.items-list-content {
	margin: 0 10% 3% 10%;
	display: flex;
	flex-flow: column;
}

.desktop-list-title {
	text-align: center;
	font-family: "Klinic Slab";
	text-transform: uppercase;
	font-size: 1.8rem;
	font-weight: 500;
	color: var(--ion-color-black);
	border-bottom: 1px solid lightgray;
	/* margin-bottom: 32px; */
	padding-bottom: 10px;
	margin: 4% 10% 0 10%;
}

.subcategory-name {
	grid-column-end: span 4;
	margin: 2% 0;
	font-family: "Klinic Slab";
	font-weight: 500;
	color: var(--ion-color-black);
	font-size: 1.3rem;
}

.veritcal-item {
	margin-bottom: 0;
	cursor: pointer;
}

.veritcal-item-image {
	height: 110px;
}

.modal {
	--width: 90vw;
}

.item-description {
	line-height: 1.2;
}

ion-modal.modal-auto-height .modal-wrapper {
	width: 90vw;
	/* height: 75vh; */
}

.desktop-byob-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.desktop-byob-title h2 {
	flex: 1 1;
	text-align: center;
	margin: 0;
	text-transform: uppercase;
	font-family: "Klinic Slab";
	font-weight: 500;
	color: var(--ion-color-black);
	font-size: 1.8rem;
}

.desktop-byob-title ion-icon {
	font-size: 1.5rem;
	color: var(--ion-color-black);
	cursor: pointer;
}

.byob-header {
	margin-top: 20px;
}

.view-basket-button {
	z-index: 10;
}

.desktop-byob-wrapper {
	margin: 2% 10% 0 10%;
}

.horizontal-slider {
	overflow: hidden;
	height: 260px;
}

.item-slide-image {
	height: 100px;
	width: 100px;
}

.item-slide-name {
	font-size: 12px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 100px; /* WITH OF ITEM-SLIDE-IMAGE */
	text-align: center;
}

.category-title {
	margin: 2%;
	font-family: "Klinic Slab";
	font-weight: 500;
	color: var(--ion-color-black);
	font-size: 1.3rem;
}

/* .desktop-sauce-wrapper {
	margin: 0 10%;
} */

.desktop-list-title.sauce-drinks {
	display: none;
}

.desktop-list-title.sauce-drinks:first-of-type {
	display: block;
}

.category-title-byob {
	font-family: "Klinic Slab";
}

@media screen and (min-width: 992px) {

	.items-list-content {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 40px;
		flex: 1 1 auto;
		
	}

	.veritcal-item-image {
		height: 250px;
	}

	.view-basket-button {
		width: 80%;
		left: 10%;
	}

	.view-basket-button ion-button {
		width: 30%;
		margin: 0 auto;
	}

	.modal {
		--width: 40vw;
	}

	ion-modal.modal-auto-height .modal-wrapper {
		width: 40vw;
	}


	.item-slide-image {
		height: 170px;
		width: 170px;
	}

	.item-slide-name {
		width: 170px;
		font-size: 18px;
	}

	.horizontal-slider {
		height: 260px;
	}
}


@media screen and (min-width: 1441px) {

	.desktop-list-title,
	.desktop-byob-title h2 {
		font-size: 2.5rem;
	}

	.modal {
		--width: 30vw;
	}

	ion-modal.modal-auto-height .modal-wrapper {
		width: 25vw;
	}

	/* .item-slide-image {
		height: 200px;
		width: 200px;
	}

	.item-slide-name {
		width: 200px;
		font-size: 22px;
		height: 30px;
	}


	.horizontal-slider {
		height: 300px;
	} */
}
/* .view-basket-button {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 20px;
	border-top: 1px solid lightgray;
	background-color: white;
}

.items-list-content {
	margin: 0 0 100px 0;
	display: flex;
}

.subcategory-name {
	grid-column-end: unset;
	margin-bottom: 20px;
	margin-top: -10px;
	font-size: 18px;
	font-weight: bold;
	font-family: unset;
	color: inherit;
}

.veritcal-item {
	margin-bottom: 20px;
}

.veritcal-item-image {
	height: 110px;
}

.modal {
	--width: 90vw;
}

.item-description {
	line-height: 2;
}

ion-modal.modal-auto-height .modal-wrapper {
  width: 90vw;
}

.byob-header {
	margin-top: -35px;
}

.desktop-byob-wrapper {
	margin: 0;
}

.horizontal-slider {
	overflow: unset;
	height: 195px;
}

.horizontal-slider-content-byob {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.item-slide-image {
	height: 100px;
	width: 100px;
}

.item-slide-name {
	font-size: 12px;
}

.category-title {
	margin-bottom: 60px;
	margin-top: -20px;
	font-family: initial;
	color: unset;
	font-weight: 600;
	font-size: 18px;
}

.desktop-sauce-wrapper {
	margin: 0;
}

.category-title-byob {
	font-family: initial;
	position: absolute;
}

.item-slide-image-selected {
	height: 100px;
	width: 100px;
} */
.checkout-input {
	margin-bottom: 10px;
}

.checkout-input:last-of-type {
	margin-bottom: 20px;
}

.card_label {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--ion-color-black);
}

.card_name {
	--padding-start: 15px;
	--padding-top: 15px;
	--padding-bottom: 15px;
	border: 1px solid var(--ion-color-black);
}

.saved-cards-header {
	color: var(--ion-color-black);
	text-align: center;
	margin: 0;
}

.no-saved-cards {
	color: var(--ion-color-black);
	text-align: center;
	margin: 10px 0;
}

.saved-cards-wrapper {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}

.add-new-card-button {
	margin-left: 0;
	margin-right: 0;
}

.card-cvc-element,
.card-expiry-element,
.card-number-element {
	border: 1px solid var(--ion-color-black);
	padding: 15px;
}

#checkout-form ion-button {
	--border-radius: 0;
}

#checkout-form .cards-list {
	margin-bottom: 40px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

#checkout-form .card-list label {
	color: black;
}

#checkout-form .web-stripe-input {
	border: 1px solid var(--ion-color-black);
  padding: 15px;
	margin-bottom: 15px;
}

#checkout-form .card-name-input {
	border: 1px solid var(--ion-color-black);
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: 15px;
}

.history-content {
    text-align: center;
    padding: 20px;
}
.desktop-checkout-wrapper {
	background: #fff;
	max-width: 90%;
	margin: 0 auto 3% auto;
	width: 100%;
}

.desktop-checkout-wrapper form {
	max-width: 90%;
	width: 100%;
	margin: 0 auto;
}

.checkout-input {
	line-height: 2.5;
}

.checkout-input label {
	color: var(--ion-color-black);
}

.desktop-checkout-title {
	background: var(--ion-color-white);
	color: var(--ion-color-black);
	text-align: center;
	font-size: 2rem;
	text-transform: uppercase;
	font-family: "Klinic Slab";
	padding: 20px;
	margin: 3% auto;
	max-width: 90%;
	width: 100%;
	font-weight: 500;
}

.web-stripe-input {
  min-height: 20px;
  display: flex;
  flex: 1 1;
  align-items: center;
	padding-bottom: 10px;
  border-bottom: 1px solid transparent;
}

.web-stripe-input > .__PrivateStripeElement {
  width: 100%;
}

ion-list .card-list ion-item {
  padding-left: 7px;
}

#checkout-form .card-list {
	background-color: transparent;
}

#checkout-form .card-list ion-item {
	background-color: transparent;
	--background: transparent;
	--border-width: 0;
}

.web-stripe-input-active {
  border-bottom: 2px solid var(--ion-color-primary);
}

.card-add-wrapper .data-picker-input {
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.13);
  border-color: var(--ion-item-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.13))));
}

#checkout-form .payment-card-input {
  border: 0;
  outline: none;
  flex: 1 1;
  padding-left: 0;
	background-color: transparent;
	--background: transparent;
}

@media screen and (min-width: 768px) {

	.desktop-checkout-wrapper {
		max-width: 70%;
	}

	.desktop-checkout-title {
			max-width: 70%;
	}
}

@media screen and (min-width: 992px) {
	.desktop-checkout-wrapper {
		max-width: 50%;
	}

	.desktop-checkout-title {
		max-width: 50%;
	}

	.desktop-checkout-wrapper form {
		max-width: 80%;
	}
}

/* .desktop-checkout-wrapper {
	max-width: 100%;
	background: unset;
} */
.order-holder {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    background: var(--ion-color-white);
    min-width: 100%;
    width: 100%;
    height: 110px;
    margin-right: 15px;
}

.order-holder:last-of-type {
    margin-right: 0;
}

.order-holder h2 {
    /* font-size: 18px; */
    margin: 0;
    color: var(--ion-color-black);
    text-transform: uppercase;
    font-weight: 500;
}

.payment {
    align-self: flex-end;
    border-radius: 15px;
    color: #fff;
    background-color: #72d0eb;
    padding: 0 8px;
    margin-bottom: 13px;
}

.payment p {
    margin: 0px;
    font-size: 12px;
    font-weight: 700;
}

.order-inner-holder {
    display: flex;
    justify-content: space-between;
    /* flex-direction: column;
    height: 70%; */
    padding: 0 5px;
}

.basket-title {
    display: flex;
    justify-content: center;
    font-size: 24px;
}

basket-item{
    width: 100%;
}

.basket-item-content {
    width: 100%;
}

.my-orders-header {
	margin: 6% 10% 3% 10%;
	background: var(--ion-color-white);
	color: var(--ion-color-black);
	padding: 20px;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Klinic Slab';
	font-size: 1.8rem;
	font-weight: 500;
}

/* .desktop-orders-wrapper {
	display: flex;
	margin: 0 10% 3% 10%;
} */

.order-inner-holder {
	flex-direction: column-reverse;
}

.order-holder h2:last-of-type {
	color: var(--ion-color-tertiary);
	font-size: 13px;
	margin-bottom: 5px;
}

.order-holder h2 {
	font-size: 15px;
}

.order-status {
	align-self: center;
	color: var(--ion-color-white);
	background-color: var(--ion-color-primary);
	padding: 8px 20px;
	width: 45%;
	text-align: center;
}

.order-status p {
	margin: 0;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
}


/* .desktop-order-details {
	width: 50%;
	display: flex;
	margin-left: 2%;
} */

.my-orders-wraper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 10% 3% 10%;
}

.desktop-orders-list {
	display: flex;
	flex-flow: row;
	margin-bottom: 3%;
	overflow-x: auto;
}

.menu-box ion-list.first-list {
	border-bottom: 1px solid var(--ion-color-black);
}

.details-title {
	background: var(--ion-color-primary);
	color: var(--ion-color-white);
	font-size: 1.1rem;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	padding: 10px;
	margin: 15px;
}

.total-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
}

.total-box h2 {
	font-size: 15px;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
	font-weight: 700;
}

@media screen and (min-width: 768px) {
	.my-orders-wraper {
		flex-flow: row;
	}

	.desktop-orders-list {
		overflow: unset;
    flex-flow: column;
    width: 45%;
    background: var(--ion-color-white);
		padding: 10px 30px;
		margin-bottom: 0;
	}

	.order-holder {
		border-bottom: 1px solid var(--ion-color-black);
    margin-right: 0;
		padding: 0;
		height: 90px;
	}
	
	.order-holder:last-of-type {
		border-bottom: none;
	}

	.desktop-order-details-wrapper {
		flex: 1 1 auto;
    margin-left: 3%;
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
	}

	.details-title {
		margin: 30px 30px 0px 30px;
	}

	.desktop-order-details-wrapper ion-grid {
		padding: 0 1px;
	}

	.menu-box ion-list.first-list {
		padding: 0 20px;
	}

	.total-box {
		padding: 15px 35px;
	}

}

@media screen and (min-width: 992px) {
	.my-orders-header {
		font-size: 2.5rem;
		margin: 3% 10% 3% 10%;
	}

	.order-holder h2 {
		font-size: 18px;
	}

	.order-status {
		width: 35%;
	}

	.details-title {
		font-size: 1.3rem;
	}

	.desktop-order-details-wrapper ion-grid {
		padding: 0 1px;
	}

	.menu-box ion-list.first-list {
		padding: 0 20px;
	}

	.total-box {
		padding: 15px 35px;
	}
}
/* .order-holder {
	margin: 0;
	justify-content: space-between;
}

.order-holder h2 {
	font-size: 18px;
	text-transform: initial;
}

.order-inner-holder {
	flex-direction: column;
	height: 70%;
}

.order-holder h2:last-of-type {
	color: var(--ion-color-black);
	text-transform: initial;
	font-size: 18px;
	margin: 0;
} */
.end {
    display: flex;
    justify-content: flex-end;
}

ion-grid {
   padding: 0px
}

.payment {
    align-self: flex-end;
    border-radius: 15px;
    color: #fff;
    background-color: #72d0eb;
    padding: 0 8px;
    margin-bottom: 13px;
}

.payment p {
    margin: 0px;
    font-size: 12px;
}

ion-row.row {
    padding: 0 15px;
}

ion-col h2 {
    font-size: 16px;
    color: #000;
    color: initial;
}
/* .menu-box ion-list{
    background: var(--ion-color-light, #f4f5f8) !important;
} */

.menu-box-item {
    width: 100%;
    /* padding-left: 10px; */
}

/* .menu-box-item ion-item {
    --background: var(--ion-color-light, #f4f5f8) !important;
} */

.menu-box-item-content {
    width: 100%;
}

/* .menu-box-item-content h2 {
    
} */

.menu-box-sub-items {
    width: 100%;
}

.menu-box-subItem-content {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.item-label ion-label {
    text-overflow: unset; 
    white-space: unset;
    overflow: unset;
    flex: 2 1;
}

.menu-box-item-content  ion-label {
    padding: 0;
    /* margin: 0 0 0 10px; */
}

.menu-box-item-content  ion-icon {
    padding: 0;
    margin: 0;
}

.menu-box-item-content  p {
    padding: 0;
    margin: 0;
    flex:1 1;
    display: flex;
    justify-content: flex-end;
}

.munu-box-total {
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 200px;
    display: flex;
    justify-content: space-between;
    padding-right: 35px;
}

.munu-box-total h2 {
    font-size: 16px;
}

.order-details-header {
	margin: 6% 10% 3% 10%;
	background: var(--ion-color-white);
	color: var(--ion-color-black);
	padding: 20px;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Klinic Slab';
	font-size: 1.8rem;
	font-weight: 500;
}

.desktop-order-details-wrapper {
	background: var(--ion-color-white);
}

.desktop-order-details-wrapper ion-grid {
	background: var(--ion-color-white);
	color: var(--ion-color-black);
	padding-bottom: 15px;
	border-bottom: 1px solid;
}

.menu-box {
	background: var(--ion-color-white);
	color: var(--ion-color-black);
}

.menu-box ion-list,
.menu-box-item ion-item {
	background: var(--ion-color-white) !important;
}

.menu-box-item ion-item {
	--inner-padding-end: 0;
	--min-height: 30px
}

.menu-box-item {
	padding: 15px;
}

.menu-box-item-content ion-label {
	margin: 0;
	font-size: 15px!important;
	text-transform: uppercase;
	font-weight: 500;
}

.menu-box-item-content h2 {
	margin: 0;
	padding: 0;
	font-size: 15px;
}

.menu-box-subItem-content ion-label {
	font-size: 12px!important;
}

.menu-box-subItem-content h2 {
	font-size: 12px;
}

.order-details-grid ion-row {
	height: auto;
}

.order-details-grid ion-row ion-col {
	display: flex;
	align-items: center;
	padding: 15px 0 0 0;
}

.order-details-grid ion-row ion-col h2 {
	font-size: 15px;
	margin: 0;
	text-transform: uppercase;
}

.order-details-status {
	align-self: center;
	color: var(--ion-color-white);
	background: var(--ion-color-primary);
	padding: 5px 30px;
}

.order-details-status p {
	margin: 0;
	font-size: 12px;
	text-transform: uppercase;
}

@media screen and (min-width: 992px) {

	.order-details-grid ion-row ion-col h2,
	.menu-box-item-content ion-label,
	.menu-box-item-content h2 {
		font-size: 17px;
	}

	.menu-box-subItem-content ion-label,
	.menu-box-subItem-content h2 {
		font-size: 13px!important;
	}

	/* .details-title {
		font-size: 1.3rem;
	} */

	.order-details-header {
		font-size: 2.5rem;
    margin: 4% 10% 3% 10%;
	}

}
/* .menu-box {
	background: unset;
}

.menu-box ion-list{
	background: var(--ion-color-light, #f4f5f8) !important;
}

.menu-box-item ion-item {
	--background: var(--ion-color-light, #f4f5f8) !important;
	--inner-padding-end: 0;
	--min-height: 48px;
}

.menu-box-item {
	padding: 15px;
	border-bottom: none;
}

.menu-box-item-content ion-label {
	margin: 0 0 0 10px;
}

.menu-box-item-content h2 {
	font-size: 16px;
	padding-right: 5px;
}

.menu-box-subItem-content ion-label {
	font-size: inherit;
} */

/* .vouchers-container {
    padding: 0 30px 30px 30px;
    margin-bottom: 70px;
} */

.vouchers-container p {
	color: var(--ion-color-dark);
}

.vouchers-head-title {
    font-weight: 500;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}

.voucher-content {
	overflow-y: auto;
    color: var(--ion-color-dark);
    text-align: center;
}

.voucher-content p {
	margin-top: 10px;
}

.voucher-reward-image {
	width: 100%;
	padding-bottom: 40%;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 100% auto;
}

.vouchers-buttons {
    /* padding: 10px 40px 10px 40px; */
    background-color: white;
    border-top: 1px solid lightgray;
    /* position: fixed; */
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.voucher {
    border: 1px solid var(--ion-color-primary);
}

.submit-code-button {
	margin: 20px 0 15px 0;
}

.box-padded {
	padding: 10px 20px;
}

.loyalty-modal > .modal-wrapper > .ion-page,
.validate-modal > .modal-wrapper > .ion-page,
.verification-modal > .modal-wrapper > .ion-page {
	justify-content: space-between;
}

.ion-text-center p {
	margin: 5px 0px 0px 0px;
}

/* ion-title.ion-text-center.modal-title {
	padding: 3px 20px 0px 0px;
} */

.loyalty-modal > .modal-wrapper > .ion-page ion-button,
.validate-modal > .modal-wrapper > .ion-page ion-button,
.verification-modal > .modal-wrapper > .ion-page ion-button {
	padding: 0 20px;
}

ion-buttons.close ion-button {
	display: flex;
	justify-content: flex-end;
	
}

.loyalty-modal > .modal-wrapper > .ion-page ion-buttons > ion-button,
.validate-modal > .modal-wrapper > .ion-page ion-buttons > ion-button,
.verification-modal > .modal-wrapper > .ion-page ion-buttons > ion-button  {
	padding: 0px;
}

.vouchers-container .ion-text-center ion-button {
   margin: 20px 0;
}

.add-voucher-title {
	color: var(--ion-color-black);
	font-family: "Klinic Slab", sans-serif;
	font-size: 25px;
	margin-top: 6px;
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	.vouchers-buttons {
		transform: translate3d(0,0,0);
		bottom: var(--ion-tab-bar-height);
	}
}

@media screen and (min-width: 768px) {
	.submit-voucher-button {
		width: 70%;
    margin: 10px auto 20px auto;
	}

	.voucher-input-wrapper {
		margin: 0 auto;
		width: 70%;
	}
}
.vouchers-container {
	padding: 30px 0;
	max-width: 90%;
	width: 100%;
	margin: 3% auto;
	background: var(--ion-color-white);
}

.vouchers-buttons {
	position: relative;
	padding: 10px 20px 0 20px;
}

.vouchers-buttons > ion-button:last-of-type {
	margin-bottom: 0;
}

.desktop-vouchers-title {
	color: var(--ion-color-black);
	font-size: 1.8rem;
	font-family: "Klinic Slab";
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
}

.enter-code-wrapper {
	padding: 0 20px;
}

.vouchers-list-wrapper {
	padding: 0 20px;
}

.vouchers-list-wrapper > div {
	margin-bottom: 20px;
}

.basket-special-notes ion-label,
.basket-special-notes p {
	font-size: 16px;
}

.vouchers-head-title {
	color: #000;
}

.loyalty-modal > .modal-wrapper {
	--max-height: 257px;
	--max-width: 90vw;
	--border-radius: 5px;
}

.validate-modal > .modal-wrapper {
	--max-height: 257px;
	--max-width: 90vw;
	--border-radius: 15px;
}

.verification-modal > .modal-wrapper {
	--max-height: 225px;
	--max-width: 90vw;
	--border-radius: 15px;
}

@media screen and (min-width: 768px) {
	.vouchers-list-wrapper > div {
		display: flex;
		align-items: center;
	}

	.vouchers-list-wrapper > div > .voucher-reward-image {
		width: 30%;
		padding-bottom: 15%;
		background-size: cover;
	}

	.vouchers-list-wrapper > div > .voucher-content {
		margin-left: 30px;
		font-weight: 500;
	}

	.vouchers-buttons {
		padding: 30px 20px 0 20px;
		flex-direction: row;
	}

	.vouchers-buttons > ion-button {
		width: 50%;
	}

	.desktop-vouchers-title {
		font-size: 2.5rem;
	}

	/* .loyalty-modal > .modal-wrapper,
	.validate-modal > .modal-wrapper {
		--max-height: 235px;
	} */
}

@media screen and (min-width: 992px) {
	.vouchers-container {
		max-width: 50%;
	}

	.enter-code-wrapper,
	.vouchers-list-wrapper {
		width: 75%;
		margin: 0 auto;
	}

	.vouchers-list-wrapper > div > .voucher-reward-image {
		padding-bottom: 17%;
	}

	.vouchers-buttons {
		padding: 30px 15% 0 15%;
		justify-content: center;
	}
}
/* .validate-modal > .modal-wrapper {
	--max-height: 255px;
	--max-width: 280px;
	--border-radius: 15px;
}

.verification-modal-modal > .modal-wrapper {
	--max-height: 220px;
	--max-width: 280px;
	--border-radius: 15px;
}

.vouchers-container {
	padding: 0 30px 30px;
	margin: 0 0 70px 0;
	max-width: 100%;
}

.vouchers-buttons {
	position: fixed;
	padding: 10px 40px;
}

.enter-code-wrapper {
	padding: var(--ion-padding);
} */
.desktop-categories-wrapper {
	margin: 5% 10% 0 10%;
}

.desktop-categories-wrapper:last-of-type {
	margin: 3% 10% 5% 10%;
}

.desktop-menu-title {
	background: var(--ion-color-white);
	color: var(--ion-color-black);
	text-align: center;
	padding: 20px;
	margin: 6% 10% 3% 10%;
}

.desktop-menu-title h1 {
	text-transform: uppercase;
	font-family: "Klinic Slab";
	font-size: 1.8rem;
	font-weight: 500;
	margin: 0;
}

.desktop-menu-title div p {
	margin: 0;
	color: var(--ion-color-tertiary);
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: 500;
}

.view-basket-button-webmenu {
	position: -webkit-sticky;
	position: sticky;
	bottom: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
}

.view-basket-button-webmenu ion-button {
	width: 60%;
	margin: 20px;
}

.subcategory-name {
	background: var(--ion-color-tertiary);
	margin: 0 0 6% 0;
	font-weight: 700;
	color: var(--ion-color-white);
	font-size: 1.3rem;
	padding: 7px 0;
	font-family: inherit;
}

.web-menu {
	display: flex;
	flex-flow: column;
}

.web-menu-item {
	background: var(--ion-color-white);
	display: flex;
	flex-flow: column;
	padding: 20px;
	margin-bottom: 20px;
	cursor: pointer;
}

.web-menu-item:last-of-type {
	margin-bottom: 0;
}

.web-menu-item-image {
	flex: 0 1 270px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
}

.web-menu-item-image-selected {
	flex: 0 1 270px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
}

.web-selected-item-checkmark {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(var(--ion-color-primary-rgb), 0.5);
}

.web-selected-item-checkmark ion-icon,
.web-hover-item ion-icon {
	font-size: 60px;
	border: 5px solid;
	border-radius: 50%;
	padding: 20px;
}

.web-hover-item {
	position: absolute;
	height: 100%;
	width: 100%;
	display: none;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	background: rgba(var(--ion-color-primary-rgb), 0.5);
}

.web-hover-item-info {
	position: absolute;
	top: 5px;
	padding-top: 10px;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
}

.web-menu-item:hover .web-hover-item {
	display: flex;
}

.web-menu-item.in-basket:hover .web-hover-item {
	display: none;
}

.web-hover-item-info p {
	color: var(--ion-color-white);
	font-weight: 700;
	margin: 0;
}

.web-menu-item-info {
	display: flex;
	justify-content: space-between;
	color: var(--ion-color-black);
	text-transform: uppercase;
	font-size: 0.9rem;
	margin-top: 15px;
	font-weight: 500;
}

.web-menu-item-name {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.web-menu-item-price {
	width: 30%;
	text-align: right;
}

.web-menu-item-description {
	color: var(--ion-color-black);
	font-size: 0.9rem;
	margin-top: 5px;
	font-weight: 500;
}

.item-slide-image-selected {
	width: 100px;
	height: 100px;
}

.byob-webmenu {
	display: flex;
	flex-flow: column;
	height: 600px;
	margin: 0 10% 0 10%;
	background: var(--ion-color-white);
}

.byob-image-webmenu {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
}

.byob-info-webmenu {
	width: 100%;
	height: 100%;
	color: var(--ion-color-black);
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
}

.byob-info-webmenu h2,
.byob-info-webmenu p {
	text-transform: uppercase;
	text-align: center;
	font-weight: 500;
	margin: 20px 0;
}

.byob-info-webmenu h2 {
	margin: 0;
	font-family: "Klinic Slab", sans-serif;
}

.byob-info-webmenu p {
	font-size: 0.9rem;
	color: var(--ion-color-tertiary);
}

.byob-info-webmenu ion-button {
	width: 80%;
	margin: 0;
}

ion-modal.modal-auto-height .modal-wrapper .ion-page .item-content {
	display: flex;
	flex-flow: column;
	overflow: auto;
	max-height: 90vh;
}

.item-header {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	background: var(--ion-color-white);
	z-index: 1;
	position: relative;
	flex-grow: 0;
}

.item-actions {
	padding: 0 20px 20px 20px;
}

.item-actions-header {
	text-align: center;
	font-size: 20px;
	margin: 10px 0;
}

.item-name {
	text-transform: uppercase;
	font-size: 25px;
	font-family: "Klinic Slab", sans-serif;
	color: var(--ion-color-black);
	margin-top: 6px;
}

.item-close {
	position: absolute;
	right: 10px;
	top: -7px;
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}


.item-actions-base {
	padding-left: 20px;
	padding-right: 20px;
	flex: 1 1 auto;
	overflow-y: auto;
}

.item-actions-base ion-list .list-header {
	padding: 0;
}

.item-actions-base ion-list .list-header ion-label {
	width: 100%;
	text-align: center;
	text-transform: uppercase;
}

.item-action-modifier-price {
	margin: 0;
	font-size: 0.8rem;
}

.item-actions-footer {
	display: flex;
	flex-flow: column;
	flex-grow: 0;
}

.special-notes {
	padding: 20px;
	background-color: var(--ion-color-white);
	z-index: 30;
}

.special-notes ion-label {
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: 500;
}

.special-notes ion-input {
	border: 1px solid;
	--padding-start: 10px;
	margin-top: 5px;
}

.select-base {
	margin: 0px auto 20px auto;
	width: 70%;
}

.select-base-item,
.select-modifier-item {
	--min-height: 30px;
	--inner-padding-start: 15px;
}

.select-base-item ion-label,
.select-modifier-item ion-label {
	font-size: 0.75rem!important;
	text-transform: uppercase;
	font-weight: 500;
	margin: 0;
}

.select-modifier-item ion-checkbox {
	margin: 9px 0 9px 32px;
}

.select-base-content, 
.select-modifiers-content {
	max-height: 140px;
	overflow-y: auto;
}

.arrow-down {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.sauces {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	margin: 6% 5% 3% 5%;
}

.sauces-item {
	display: flex;
	flex-flow: column;
}

.sauces-item-slide-image,
.sauces-item-slide-image-selected {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	flex: 0 1 100px;
	height: 100px;
	border-radius: 10px;
	position: relative;
}

.sauces-selected-item-checkmark {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(var(--ion-color-primary-rgb), 0.5);
	border-radius: 15px;
}

.sauces-selected-item-checkmark ion-icon {
	font-size: 30px;
	border: 2px solid;
	border-radius: 50%;
	padding: 10px;
}

.sauces-item-slide-name,
.sauces-item-slide-price {
	text-align: center;
	font-weight: 500;
}

.sauces-item-slide-name {
	margin: 7px 0 0 0;
}

.sauces-item-slide-price {
	margin: 0 0 20px 0;
}

.sauces-add-sauce {
	margin: 10px auto 20px auto;
	width: 70%;
	flex: 0 0 50px;
}

.sauce.category-title {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0 30px 0;
	background: var(--ion-color-white);
	z-index: 1;
	font-size: 25px;
	position: relative;
}

.sauce.category-title ion-icon {
	position: absolute;
	right: -10px;
	top: -7px;
	font-size: 40px;	
}

.desktop-sauce-wrapper {
	/* margin: 0 5%; */
	flex: 1 1 100%;
    overflow-y: auto
}

.choose-size {
	--inner-padding-end: 0;
	display: flex;
	cursor: pointer;
}

.choose-size ion-label {
	text-transform: uppercase;
	font-weight: 500;
	margin: 0;
	flex: none;
}

.choose-size ion-label:last-of-type {
	text-align: right;
}

.choose-size:last-of-type {
	margin-bottom: 5px;
}

/* @media screen and (min-width: 520px) and (max-width: 991px) {
	.desktop-categories-wrapper {
		max-width: 60vw;
		margin: 0 auto;
	}
} */

.item-third-header {
	display: flex;
}

.item-third-header ion-label {
	text-transform: uppercase;
	color: var(--ion-color-black);
	margin: auto;
	margin-top: 10px;
	margin-bottom: 5px;
	font-size: 24px;
	text-align: center;
}
.item-third-header ion-icon {
	position: absolute;
	right: 10px;
	top: 3px;
	font-size: 40px;
	color: var(--ion-color-black);
}

.select-base-button.quantity {
	margin: 0 auto 20px auto;
	width: 70%;
}

.sauces-content {
	max-height: 90vh;
    display: flex;
    flex-direction: column;
}


.sauces-content > ion-toolbar {
	flex: 0 0 50px;
}




@media screen and (min-width: 768px) {

	.desktop-categories-wrapper {
		margin: 3% 10% 0 10%;
	}

	.desktop-sauces-wrapper {
		margin: 0 15%;
	}

	.web-menu {
		display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
	}

	.web-menu-item {
		margin: 0;
	}

	.subcategory-name {
		margin: 0 0 3% 0;
	}

	.view-basket-button-webmenu ion-button {
		width: 35%;
	}

	.sauces {
		grid-gap: 20px;
	}

	.sauces-item-slide-image,
	.sauces-item-slide-image-selected {
		flex: 0 1 140px;
		height: 140px;
	}
}

@media screen and (min-width: 992px) {
	.desktop-categories-wrapper {
		max-width: none;
		max-width: initial;
		align-content: center;
		flex: 1 1 auto;
		padding-top: 0;
	}

	.web-menu {
		grid-template-columns: repeat(3, 1fr);
	}

	.desktop-menu-title {
		margin: 4% 10% 0 10%;
	}
	
	.desktop-menu-title h1 {
		font-size: 2.5rem;
	}

	.category-item {
		cursor: pointer;
	}

	.view-basket-button-menu {
		width: 45%;
		margin: 0 0 3% 0;
	}

	.subcategory-name {
		font-size: 1.5rem;
	}

	.view-basket-button-webmenu ion-button {
		width: 20%;
	}

	.byob-webmenu {
		flex-flow: row;
		height: 300px;
		margin: 3% 10% 0 10%;
	}

	.byob-image-webmenu,
	.byob-info-webmenu {
		width: 50%;
	}

	.byob-info-webmenu h2 {
		font-size: 2rem;
	}

	.select-base-content, .select-modifiers-content {
		/* min-height: 170px; */
	}
}

@media screen and (min-width: 1200px) {
	.web-menu-item-image,
	.web-menu-item-image-selected {
		flex: 0 1 350px;
	}

	.byob-info-webmenu {
		padding: 0 10%;	
	}

	.sauces-item-slide-image,
	.sauces-item-slide-image-selected {
		flex: 0 1 150px;
		height: 150px;
	}

	.sauces-selected-item-checkmark ion-icon {
		font-size: 40px;
		padding: 15px;
	}
}

@media screen and (min-width: 1441px) {
	.web-menu {
		grid-template-columns: repeat(4, 1fr);
	}

	.view-basket-button-webmenu ion-button {
		width: 15%;
	}

	.select-base-content {
		min-height: 400px;
	}

	.select-modifiers-content {
		min-height: 200px;
	}

	.sauces-item-slide-image,
	.sauces-item-slide-image-selected {
		flex: 0 1 170px;
		height: 170px;
	}

	.sauces-selected-item-checkmark ion-icon {
		font-size: 50px;
		padding: 20px;
	}
}
.web-byob-wrapper {
  margin: 3% 10% 0 10%;
}

.web-byob-header-title {
  background: var(--ion-color-white);
  color: var(--ion-color-black);
  font-family: "Klinic Slab", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  text-transform: uppercase;
  padding: 20px;
  text-align: center;
  margin: 3% 10% 0 10%;
}

.web-category-title-byob {
  background: var(--ion-color-tertiary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-white);
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 10px;
  cursor: pointer;
}

.web-byob-info-wrapper {
  display: flex;
  flex-flow: column;
  margin-bottom: 3%;
  padding: 20px;
  background: var(--ion-color-white);
}

.web-byob-info-total {
  margin: 10px 0 0 0;
  font-weight: 500;
}

.web-byob-info-title {
  margin: 10px 0;
  text-transform: uppercase;
  font-size: 1.3rem;
}

.web-byob-image,
.web-top-image,
.web-additional-top-image,
.web-finish-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.web-byob-image {
  flex: 1 1 270px; /* FLEX BASIS 270PX */
  height: 270px; /* SAME AS FLEX BASIS */
  position: relative;
}

.web-top-image,
.web-additional-top-image,
.web-finish-image {
  height: 100%;
  width: 100%;
  position: absolute;
}

.web-byob-info {
  color: var(--ion-color-black);
}

.web-byob-info ion-button {
  margin: 20px 0 0 0;
}

.web-byob-section {
  margin-bottom: 20px;
}

.web-byob-section:last-of-type {
  margin-bottom: 0;
}

.web-byob-content-inner {
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1),
    padding 0.3s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
  padding: 0;
}

.web-byob-content-inner.expanded {
  max-height: 9999px;
  padding: 20px 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1),
    padding 0.3s cubic-bezier(0, 1, 0, 1);
}

.web-byob-content-inner.expanded:last-of-type {
  padding-bottom: 0;
}

.web-item-byob {
  background: var(--ion-color-white);
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  cursor: pointer;
}

.web-item-byob:hover .web-hover-item {
  display: flex;
}

.web-item-byob.selected:hover .web-hover-item {
  display: none;
}

.web-item-byob-image {
  flex: 1 1 270px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.web-byob-item-info,
.web-byob-info-item {
  display: flex;
  justify-content: space-between;
  color: var(--ion-color-black);
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
}

.web-byob-item-info {
  padding: 15px;
}

.web-item-byob-image-selected {
  flex: 1 1 270px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.web-item-byob-name,
.web-byob-info-name {
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.web-item-byob-price,
.web-byob-info-price {
  width: 30%;
  text-align: right;
}

.web-category-title-icon {
  margin-left: 10px;
  transform: rotateX(0);
  transition: all 0.3s ease;
}

.web-category-title-icon.expanded {
  transform: rotateX(180deg);
  transition: all 0.3s ease;
}

.special-notes-actions {
  margin: 10px auto 20px auto;
  padding: 10px;
  width: 70%;
  border: 1px solid;
}

ion-button.special-notes-button {
  margin: 0 auto 20px auto;
  width: 70%;
}

@media screen and (min-width: 768px) {
  .web-byob-content-inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .web-item-byob {
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .web-byob-header-title {
    font-size: 2.5rem;
  }

  .web-byob-wrapper {
    display: flex;
    position: relative;
  }

  .web-byob-info-wrapper {
    min-height: 350px;
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    flex: 0 1 300px;
  }

  .web-byob-info-inner {
    max-height: 170px;
    height: 100%;
    overflow-y: auto;
  }

  .byob-right-wrapper {
    flex: 1 1 auto;
    margin-left: 20px;
    margin-bottom: 3%;
  }
}

@media screen and (min-width: 1200px) {
  .web-byob-content-inner {
    grid-template-columns: repeat(3, 1fr);
  }

  .web-byob-info-wrapper {
    flex: 0 1 350px;
  }
}

@media screen and (min-width: 1441px) {
  .web-byob-content-inner {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1921px) {
  .web-byob-info-wrapper {
    flex: 0 1 400px;
  }

  .web-byob-image {
    flex: 1 1 300px; /* FLEX BASIS 270PX */
    height: 300px; /* SAME AS FLEX BASIS */
  }
}

ion-modal.modal-auto-height.full-width .modal-wrapper {
  width: 40vw;
}

@media screen and (max-width: 1105px) {
  .sauces {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 850px) {
  ion-modal.modal-auto-height.full-width .modal-wrapper {
    width: 50vw;
  }
}

@media screen and (max-width: 645px) {
  ion-modal.modal-auto-height.full-width .modal-wrapper {
    width: 50vw;
  }

  .sauces-item-slide-image {
    flex: 0 1 150px;
  }
}

@media screen and (max-width: 630px) {
  ion-modal.modal-auto-height.full-width .modal-wrapper {
    width: 100%;
    margin: 10%;
  }

  .sauces-item-slide-image {
    flex: 0 1 160px;
  }
}

@media screen and (max-width: 400px) {
  .sauces-item-slide-image {
    flex: 0 1 140px;
  }
}

@media screen and (max-width: 350px) {
  ion-modal.modal-auto-height.full-width .modal-wrapper {
    margin: 5px;
  }
}

.end {
    display: flex;
    justify-content: flex-end;
}

ion-grid {
   padding: 0px
}

.payment {
    align-self: flex-end;
    border-radius: 15px;
    color: #fff;
    background-color: #72d0eb;
    padding: 0 8px;
    margin-bottom: 13px;
}

.payment p {
    margin: 0px;
    font-size: 12px;
}

ion-row.row {
    padding: 0 30px;
}

ion-col h2 {
    font-size: 16px;
    color: #000;
    color: initial;
}


.menu-box-item {
    width: 100%;
    /* padding-left: 10px; */
}

/* .menu-box-item ion-item {
    --background: var(--ion-color-light, #f4f5f8) !important;
} */

.menu-box-item-content {
    width: 100%;
}

/* .menu-box-item-content h2 {
    
} */

.menu-box-sub-items {
    width: 100%;
}

.menu-box-subItem-content {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.item-label ion-label {
    text-overflow: unset; 
    white-space: unset;
    overflow: unset;
    flex: 2 1;
}

.menu-box-item-content  ion-label {
    padding: 0;
    /* margin: 0 0 0 10px; */
}

.menu-box-item-content  ion-icon {
    padding: 0;
    margin: 0;
}

.menu-box-item-content  p {
    padding: 0;
    margin: 0;
    flex:1 1;
    display: flex;
    justify-content: flex-end;
}

.munu-box-total {
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 200px;
    display: flex;
    justify-content: space-between;
    padding-right: 35px;
}

.munu-box-total h2 {
    font-size: 16px;
}

.order-details-header {
	margin: 6% 10% 3% 10%;
	background: var(--ion-color-white);
	color: var(--ion-color-black);
	padding: 20px;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Klinic Slab';
	font-size: 1.8rem;
	font-weight: 500;
}

.desktop-order-complete-wrapper {
	flex: 1 1 auto;
    max-width: 90%;
    width: 100%;
    background: var(--ion-color-white);
    align-self: center;
    margin: 50px 0;
    padding: 40px 0;
}

.desktop-order-complete-wrapper ion-grid {
	background: var(--ion-color-white);
	color: var(--ion-color-black);
	padding-bottom: 15px;
	border-bottom: 1px solid;
}

.menu-box {
	background: var(--ion-color-white);
	color: var(--ion-color-black);
}

.menu-box ion-list,
.menu-box-item ion-item {
	background: var(--ion-color-white) !important;
}

.menu-box-item ion-item {
	--inner-padding-end: 0;
	--min-height: 30px
}

.desktop-order-complete-wrapper .menu-box-item {
	padding: 15px 30px;
}

.menu-box-item-content ion-label {
	margin: 0;
	font-size: 15px!important;
	text-transform: uppercase;
	font-weight: 500;
}

.menu-box-item-content h2 {
	margin: 0;
	padding: 0;
	font-size: 15px;
}

.menu-box-subItem-content ion-label {
	font-size: 12px!important;
}

.menu-box-subItem-content h2 {
	font-size: 12px;
}

.order-complete-grid ion-row {
	height: auto;
}

.order-complete-grid ion-row ion-col {
	display: flex;
	align-items: center;
	padding: 15px 0 0 0;
}

.order-complete-grid ion-row ion-col h2 {
	font-size: 15px;
	margin: 0;
	text-transform: uppercase;
}

.order-complete-status {
	align-self: center;
	color: var(--ion-color-white);
	background: var(--ion-color-primary);
	padding: 5px 30px;
}

.order-complete-status p {
	margin: 0;
	font-size: 12px;
	text-transform: uppercase;
}

.desktop-order-complete-wrapper .total-box {
	padding: 15px 30px;
}

.munu-box-total.notes {
	display: flex;
	flex-flow: column;
	margin: 20px 0 0 0;
	padding: 0;
}

.munu-box-total.notes > h2 {
	margin: 5px 0 0 0;
	text-transform: none;
}

@media screen and (min-width: 992px) {

	.order-complete-grid ion-row ion-col h2,
	.menu-box-item-content ion-label,
	.menu-box-item-content h2 {
		font-size: 17px;
	}

	.desktop-order-complete-wrapper  {
		max-width: 50%;
	}

	.desktop-order-complete-wrapper  ion-button {
		width: 35%;
	}

	.menu-box-subItem-content ion-label,
	.menu-box-subItem-content h2 {
		font-size: 13px!important;
	}

	/* .details-title {
		font-size: 1.3rem;
	} */

	.order-complete-header {
		font-size: 2.5rem;
    margin: 4% 10% 3% 10%;
	}

}

@media screen and (min-width: 768px) {
	.desktop-order-complete-wrapper  {
		max-width: 70%;
	}

	/* .desktop-order-complete-wrapper  ion-list {
		width: 65%;
	} */

	/* .desktop-profile-header {
		font-size: 2.5rem;
	} */
}



@media screen and (min-width: 1441px) {
	.desktop-order-complete-wrapper  {
		flex: unset;
		max-width: 35%;
	}
}
.icomoon {
	font-family: var(--ion-icomoon-font-family);
	font-size: 24px;
}

span > .icomoon {
	font-size: 20px;
}

.icomoon::before {
	display: block;
}

.icomoon.ico-voucher::before {
	content: '\e900';
}

.icomoon.ico-profile::before {
	content: '\e901';
}

.icomoon.ico-basket::before {
	content: '\e902';
}

.icomoon.ico-map::before {
	content: '\e903';
}

.icomoon.ico-home::before {
	content: '\e904';
}

.icomoon.ico-order::before {
	content: '\e905';
}

.icomoon.ico-social::before {
	content: '\e906';
}

.icomoon.ico-orders::before {
	content: '\e907';
}

.icomoon.ico-allergens::before {
	content: '\e908';
}

.icomoon.ico-logout::before {
	content: '\e909'
}

.icomoon.ico-feedback::before {
	content: '\e90a';
}

.icomoon.ico-radio::before {
	content: '\e90b';
}

/* ion-header.static {
	margin-bottom: -48px;
	z-index: 5;
}

ion-toolbar.primary-toolbar {
	--min-height: var(--ion-page-router-offset);
	--background: var(--ion-color-white) url(../../assets/images/island_poke_logo.svg) no-repeat 50% 50%/65% auto;
}

ion-header.static ion-toolbar:last-child {
	--min-height: var(--ion-toolbar-min-height);
}
*/

.image-button {
	flex: 1 1;
	background: transparent;
}

ion-button.basket-button {
	width: 45px;
}

ion-button .icomoon {
	position: absolute;
	right: 12px;
	color: var(--ion-color-primary)
}

ion-button ion-badge {
	margin-bottom: 14px;
	margin-left: 14px;
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	ion-toolbar.primary-toolbar {
		--background: var(--ion-color-white) url(/static/media/island_poke_logo.e726ee39.svg) no-repeat 50% 61%/65% auto;
	}
}

.desktop-header {
	display: flex;
	align-items: center;
	padding: 25px;
	justify-content: space-between;
	position: relative;
}

.desktop-header.register {
	justify-content: center;
}

.desktop-header.register > ion-img {
	width: 45%;
}

.desktop-header > ion-list {
	display: none;
}

.desktop-header > ion-list ion-item {
	--inner-padding-end: 0;
	--background-hover: transparent;
}

/* .desktop-header > ion-list ion-item:hover {
	background: transparent;
} */

.desktop-header > ion-list ion-item ion-label {
	--color: var(--ion-color-primary)!important;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
}

.desktop-header > ion-img {
	width: 45%;
	cursor: pointer;
}

.order-menu-hamburger {
	display: block;
	width:50px;
	height: 40px;
}

.right-drawer-button,
.left-menu-button {
	height: 40px!important;
	width: 40px;
}

.right-drawer-button {
	border-radius: 50%;
	--ripple-color: var(--ion-color-primary-shade)
}

.right-drawer-icon {
	color: var(--ion-color-primary-shade);
	height: 32px;
	font-size: 31px;
}


@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
	.desktop-header {
		justify-content: initial;
	}
	.desktop-header > ion-list {
		display: flex;
		width: 100%;
		justify-content: space-evenly;
	}
	
	.desktop-header.register > ion-img {
		width: 34%;
	}

	.left-menu-button {
		display: none;
	}
}

@media screen and (min-width: 1441px) {
	.desktop-header.register > ion-img {
		width: 20%;
	}
}
/* ion-header.static {
	margin-bottom: -48px;
	z-index: 5;
}

ion-toolbar.primary-toolbar {
	--min-height: var(--ion-page-router-offset);
	--background: var(--ion-color-white) url(../../assets/images/island_poke_logo.svg) no-repeat 50% 50%/65% auto;
}

ion-header.static ion-toolbar:last-child {
	--min-height: var(--ion-toolbar-min-height);
}


.image-button {
	flex: 1;
	background: transparent;
} */
.menu-enabled .list-md {
	padding-top: 0;
	padding-bottom: 0;
}

.drawer-menu .header-md, .drawer-menu .header-md {
	background-color: var(--ion-color-primary);
}

.drawer-menu .header-md {
	padding: 32px 32px 0;
}

.drawer-menu .title-default {
	text-transform: uppercase;
	font-weight: bold;
}

.drawer-menu > .ion-color {
	--padding-start: 32px;
	--padding-end: 32px;
}

.drawer-menu .title-default {
	-webkit-padding-start: 0;
	        padding-inline-start: 0;
}

.drawer-menu .nav-item {
	--padding-start: 0;
	--min-height: 24px;
}

.drawer-menu .nav-icon, .drawer-menu .nav-label {
	margin-top: 8px;
	margin-bottom: 8px;
}

.header-ios ion-toolbar:last-child{
	--border-width: 0 0 0px
} 

@media screen and (min-width: 992px) {
	.drawer-menu {
		display: none!important;
	}
}

@supports (-webkit-overflow-scrolling: touch) {
	/* iOS */
	.drawer-menu ion-header {
		padding: 20px 25px 0px 0px;
		background-color: var(--ion-color-primary);
	}
}


/* .tab-bar {
	height: var(--ion-tab-bar-height);
	flex: 0, 0, var(--ion-tab-bar-height);
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	border: 1px solid var(--ion-tab-bar-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.07))));
}

.tab {
	height: 100%;
	flex: 1 1 0%;
	text-align: center;
	color: var(--ion-tab-bar-color, var(--ion-color-step-600));
	font-size: 12px;
	cursor: pointer;
}

.tab:hover, .tab.selected {
	color: var(--ion-color-primary);
}

.tab > div {
	padding: 8px 7px 0;
	vertical-align: middle;
}

.tab .icomoon, .tab ion-icon {
	font-size: 20px;
	line-height: 25px;
}

.toast-wrapper.toast-bottom {
	bottom: calc(10px + var(--ion-tab-bar-height)) !important;
} */

.tab-basket {
	position: relative;
}

.tab-basket ion-badge {
	position: absolute;
	left: 50%;
	transform: translateX(calc(-50% + 20px));
    top: 3px;
    font-size: 12px;
    padding: 3px;
    width: 17px;
    height: 17px;
}

.tab-bar {
	display: none;
}
/* .tab-bar {
	height: var(--ion-tab-bar-height);
	flex: 0, 0, var(--ion-tab-bar-height);
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	border: 1px solid var(--ion-tab-bar-border-color,var(--ion-border-color,var(--ion-color-step-150,rgba(0,0,0,0.07))));
}

.tab {
	height: 100%;
	flex: 1 1 0%;
	text-align: center;
	color: var(--ion-tab-bar-color, var(--ion-color-step-600));
	font-size: 12px;
	cursor: pointer;
}

.tab:hover, .tab.selected {
	color: var(--ion-color-primary);
}

.tab > div {
	padding: 8px 12px 0;
	vertical-align: middle;
}

.tab .icomoon, .tab ion-icon {
	font-size: 20px;
	line-height: 25px;
}

.toast-wrapper.toast-bottom {
	bottom: calc(10px + var(--ion-tab-bar-height)) !important;
} */
@media screen {
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
	font-family: "Klinic Slab";
	src: url(/static/media/KlinicSlab-Light.6d00bf8f.woff2) format("woff2"), url(/static/media/KlinicSlab-Light.05790f18.woff) format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Brandon Grotesque";
	src: url(/static/media/BrandonGrotesque-Medium.08ee9db6.woff2) format("woff2"), url(/static/media/BrandonGrotesque-Medium.920c11f3.woff) format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Brandon Grotesque";
	src: url(/static/media/BrandonGrotesque-Regular.aff015df.woff2) format("woff2"), url(/static/media/BrandonGrotesque-Regular.013a89eb.woff) format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Klinic Slab";
	src: url(/static/media/KlinicSlab-Medium.fac20cfd.woff2) format("woff2"), url(/static/media/KlinicSlab-Medium.42f0b7b1.woff) format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Klinic Slab";
	src: url(/static/media/KlinicSlab-Bold.f8eafcf4.woff2) format("woff2"), url(/static/media/KlinicSlab-Bold.8bc8d522.woff) format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: 'Icomoon';
  src:  url(/static/media/icomoon.4d883478.eot);
  src:  url(/static/media/icomoon.4d883478.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.b86391f2.ttf) format('truetype'),
    url(/static/media/icomoon.0c1fd2b6.woff) format('woff'),
    url(/static/media/icomoon.70752652.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary:  #72d0eb;
  --ion-color-primary-rgb: 114,208,235;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #359bb4;
  --ion-color-primary-tint: #9be5f8;

  /** secondary **/
  --ion-color-secondary: #00bcb4;
  --ion-color-secondary-rgb: 0, 188, 180;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #2a6903;
  --ion-color-secondary-tint: #bbf795;

  /** tertiary **/
  --ion-color-tertiary: #ff8189;
  --ion-color-tertiary-rgb: 255,129,137;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #b44b52;
  --ion-color-tertiary-tint: #fcb9be;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f8f8f8;
  --ion-color-light-rgb: 248, 248, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #5a5757;
  --ion-color-white-contrast-rgb: 192, 192, 192;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;


  /* black */
  --ion-color-black: #000;

  /** fonts **/
  --ion-font-family: "Brandon Grotesque";
  --ion-font-size: 16px;

  /** icons **/
  --ion-icomoon-font-family: "Icomoon";

  /** form **/
  --ion-color-form-label: #b0abab;
  --ion-color-form-label-dark: #1e191a;
  --ion-color-note: #cbc6c6;

  /** custom values **/
  --ion-color-title2: #1073d0;
  --ion-color-box-holder: #fff;
  --ion-separator-color: #e6e6e6;
  --ion-feedback-label: #727272;
  --ion-top-small: 5px;
  --ion-top-medium: 30px;
  --ion-feedback-star: #9ba1a5;

  /** original theme file colors **/
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-padding: 32px;
  --ion-tab-bar-color: #777;
  --ion-tab-bar-height: 56px;
  --ion-safe-area-bottom: 56px;
  --ion-toolbar-min-height: 48px;
  --ion-page-router-offset: 80px;
/*
  --background: #fff;
  --ion-background-color: #fff;*/
}

}
.ion-color-favorite {
	--ion-color-base: #69bb7b;
	--ion-color-base-rgb: 105, 187, 123;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #5ca56c;
	--ion-color-tint: #78c288;
}

.ion-color-twitter {
	--ion-color-base: #1da1f4;
	--ion-color-base-rgb: 29, 161, 244;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1a8ed7;
	--ion-color-tint: #34aaf5;
}

.ion-color-google {
	--ion-color-base: #dc4a38;
	--ion-color-base-rgb: 220, 74, 56;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #c24131;
	--ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
	--ion-color-base: #23b6ea;
	--ion-color-base-rgb: 35, 182, 234;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #1fa0ce;
	--ion-color-tint: #39bdec;
}

.ion-color-facebook {
	--ion-color-base: #3b5998;
	--ion-color-base-rgb: 59, 89, 152;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: #344e86;
	--ion-color-tint: #4f6aa2;
}

.ion-color-primary-shade {
	--ion-color-base: var(--ion-color-primary-shade);
	--ion-color-base-rgb: 185, 3, 11;
	--ion-color-contrast: #ffffff;
	--ion-color-contrast-rgb: 255, 255, 255;
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary);
}

.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-transparent {
	--ion-color-base: transparent;
	--ion-color-base-rgb: rgba(255,255,255,0);
	--ion-color-contrast: transparent;
	--ion-color-contrast-rgb: rgba(0,0,0,0);
	--ion-color-shade: transparent;
	--ion-color-tint: transparent;
	background-color: transparent;
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

.md {
	--ion-toolbar-background: var(--ion-color-primary);
	--ion-toolbar-color: #fff;
	--ion-toolbar-color-activated: #fff;
}

.split-pane-md {
	--border: 1px solid #fff;/*0 none;*/
}

h3 {
	font-size: 16px;
	text-align: center;
	color: var(--ion-color-tertiary);
	margin-bottom: 30px;
}

h4 {
	font-size: 15px;
	color: var(--ion-color-form-label-dark);
}

.title2 {
	color: var(--ion-color-title2);
	margin: 40px 0 20px;
}

.box-holder {
	background-color: var(--ion-color-box-holder);
	border-radius: 5px;
	padding: 0px 15px;
}

.padded {
	padding: var(--ion-padding);
}

.v-padded {
	padding: var(--ion-padding) 0;
}

.h-padded {
	padding: 0 var(--ion-padding);
}

.l-padded {
	padding-left: var(--ion-padding);
}

.r-padded {
	padding-right: var(--ion-padding);
}

.t-padded {
	padding-left: var(--ion-padding);
}

.b-padded {
	padding-right: var(--ion-padding);
}

.pad10 {
	padding: 10px;
}

.pad20 {
	padding: 20px;
}

.pad40b {
	padding-bottom: 40px;
}

.pad5l {
	padding-left: 5px;
}

.box-holder ion-item {
	--padding-start: 0px;
	--border-color: var(--ion-separator-color)
}

.box-holder ion-list {
	margin-bottom: 10px
}

/* div:not(.bordered) ion-list ion-item:last-child:not(.single-item) {
	--padding-start: 0px;
	--border-width: 0px;
	--border-color: #fff;
} */

.single-item {
	margin-bottom: 10px;
}

.label-floating {
	color: var(--ion-color-primary) !important;
}

ion-label h2 {
	color: var(--ion-color-form-label-dark);
}

ion-note {
	color: var(--ion-color-note);
	font-size: 12px;
	line-height: 15px;
}

ion-label.ion-text-wrap {
	line-height: 1 !important;
}

ion-item {
	--padding-start: 0;
}

ion-item ion-button {
	margin-bottom: 0;
	height: auto;
}

.float-input-holder {
	--padding-start: 0px;
	--border-color: var(--ion-color-form-label)
}

.top-small {
	margin-top: var(--ion-top-small);
}

.top-medium {
	margin-top: var(--ion-top-medium);
}

ion-button {
	--border-radius: 20px;
	--box-shadow: none;
	margin-bottom: 10px;
	height: 40px;
	font-weight: bold;
	text-transform: uppercase;
}

ion-button.ion-color-primary {
	--border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-primary);
}

ion-button.ion-color-white {
	--border-style: solid;
	--border-width: 2px;
	--border-color: var(--ion-color-white-contrast);
}

ion-toolbar.dynamic-header {
	text-transform: uppercase;
	font-weight: bold;
}

.contrast-separator {
	display: block;
	height: 0;
	line-height: 0;
	border: 1px solid rgba(255,255,255,0.3);
	margin: 16px 0;
}

.contrast-separator.contrast-separator-mini {
	margin: 12px 0;
}

.no-padding {
	padding: 0;
}

.text-link {
	--ion-toolbar-background: transparent;
	--background-hover: transparent;
	--background-activated: transparent;
	--background-focused: transparent;
	--color: var(--ion-color-medium);
	--color-hover: var(--ion-color-dark);
	--color-activated: var(--ion-color-dark);
	--color-focused: var(--ion-color-dark);
	--ripple-color: #fff;
	text-transform: unset;
	font-size: inherit;
	font-weight: 300;
}

.segment-holder {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.segment-holder ion-segment {
	flex: 0 0 auto;
}

.segment-holder .segment-content {
	flex: 1 1 auto;
	overflow: hidden;
	overflow-y: auto;
}

ion-segment-button {
	height: 50px;
	border-radius: 0px;
	color: var(--ion-color-dark);
	border: 0 none;
	border-bottom: 4px solid var(--ion-color-primary-contrast);
	background-color: var(--ion-color-primary-contrast);
	font-weight: bold;
}

ion-segment-button.segment-button-checked {
	border-bottom-color: var(--ion-color-primary);
	color: var(--ion-color-primary);
}

ion-picker .picker-wrapper {
	background-color: #fff !important;
}

.clickable {
	cursor: pointer;
}

.field-error {
	color: var(--ion-color-danger);
	font-size: 11px;
	margin-bottom: 10px;
}

.custom-icon {
	padding-top: 12px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
}

.circle {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: #ccc;
}

.circle::after {
	display: block;
	content: '';
	padding-top: 100%;
}



