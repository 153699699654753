/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
	font-family: "Klinic Slab";
	src: url("../assets/fonts/KlinicSlab/KlinicSlab-Light.woff2") format("woff2"), url("../assets/fonts/KlinicSlab/KlinicSlab-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Brandon Grotesque";
	src: url("../assets/fonts/BrandonGrotesque/BrandonGrotesque-Medium.woff2") format("woff2"), url("../assets/fonts/BrandonGrotesque/BrandonGrotesque-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Brandon Grotesque";
	src: url("../assets/fonts/BrandonGrotesque/BrandonGrotesque-Regular.woff2") format("woff2"), url("../assets/fonts/BrandonGrotesque/BrandonGrotesque-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Klinic Slab";
	src: url("../assets/fonts/KlinicSlab/KlinicSlab-Medium.woff2") format("woff2"), url("../assets/fonts/KlinicSlab/KlinicSlab-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Klinic Slab";
	src: url("../assets/fonts/KlinicSlab/KlinicSlab-Bold.woff2") format("woff2"), url("../assets/fonts/KlinicSlab/KlinicSlab-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: 'Icomoon';
  src:  url('../assets/fonts/icomoon/icomoon.eot');
  src:  url('../assets/fonts/icomoon/icomoon.eot#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../assets/fonts/icomoon/icomoon.woff') format('woff'),
    url('../assets/fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary:  #72d0eb;
  --ion-color-primary-rgb: 114,208,235;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #359bb4;
  --ion-color-primary-tint: #9be5f8;

  /** secondary **/
  --ion-color-secondary: #00bcb4;
  --ion-color-secondary-rgb: 0, 188, 180;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #2a6903;
  --ion-color-secondary-tint: #bbf795;

  /** tertiary **/
  --ion-color-tertiary: #ff8189;
  --ion-color-tertiary-rgb: 255,129,137;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #b44b52;
  --ion-color-tertiary-tint: #fcb9be;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f8f8f8;
  --ion-color-light-rgb: 248, 248, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #5a5757;
  --ion-color-white-contrast-rgb: 192, 192, 192;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;


  /* black */
  --ion-color-black: #000;

  /** fonts **/
  --ion-font-family: "Brandon Grotesque";
  --ion-font-size: 16px;

  /** icons **/
  --ion-icomoon-font-family: "Icomoon";

  /** form **/
  --ion-color-form-label: #b0abab;
  --ion-color-form-label-dark: #1e191a;
  --ion-color-note: #cbc6c6;

  /** custom values **/
  --ion-color-title2: #1073d0;
  --ion-color-box-holder: #fff;
  --ion-separator-color: #e6e6e6;
  --ion-feedback-label: #727272;
  --ion-top-small: 5px;
  --ion-top-medium: 30px;
  --ion-feedback-star: #9ba1a5;

  /** original theme file colors **/
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-padding: 32px;
  --ion-tab-bar-color: #777;
  --ion-tab-bar-height: 56px;
  --ion-safe-area-bottom: 56px;
  --ion-toolbar-min-height: 48px;
  --ion-page-router-offset: 80px;
/*
  --background: #fff;
  --ion-background-color: #fff;*/
}
